<div style="padding-top: 25px; padding-bottom: 25px">
  <h1 style="font-size: 18px" [style.font-size]="isMobile? '18px' : '24px'">TERMOS E CONDIÇÕES</h1>
  <mat-divider></mat-divider>
  <br>
  <mat-card-title style="font-size: 20px">DEFINIÇÕES</mat-card-title>

  <br>
  <br>

  <mat-card-content>
    <p>SITE: é a empresa proprietária do PORTAL CAMPOS BELOS.</p>
    <p> PORTAL CAMPOS BELOS: é o portal cujo endereço é http://www.portalcamposbelos.com.br todos os recursos e
      ferramentas relacionadas a este.</p>
    <p> USUÁRIO(S): são as pessoas que acessam o PORTAL CAMPOS BELOS.</p>
    <p> ANUNCIANTE(S): são as pessoas ou empresas que veiculam seus anúncios ou publicidade no PORTAL CAMPOS BELOS.</p>
  </mat-card-content>

  <br>

  <mat-card-title style="font-size: 20px">OS PROCEDIMENTOS E OBSERVAÇÕES</mat-card-title>

  <br>

  <br>
  <mat-card-content>
    <p>O USUÁRIO deve ler com atenção os termos abaixo antes de acessar ou usar o PORTAL CAMPOS BELOS, pois o acesso ou
      uso deste implica em concordância com tais termos.</p>
    <p> O SITE e os demais participantes deste portal somente fornecerão ao USUÁRIO informações e serviços mediante
      expressa concordância aos termos, condições e informações aqui contidas, assim como aos demais documentos
      incorporados ao mesmo por referência. O uso do PORTAL CAMPOS BELOS significa a total concordância com tais termos,
      condições e informações.</p>
    <p> O PORTAL CAMPOS BELOS atua como veiculador de anúncios advindos de diversos anunciantes e, portanto, não atua
      como prestador de serviços de consultoria ou ainda intermediário ou participante em nenhum negócio entre o USUÁRIO
      e o(s) ANUNCIANTE(S).</p>
    <p> Dessa forma, o SITE não assume responsabilidade por nenhuma consequência que possa advir de qualquer relação
      entre o USUÁRIO e o(s) ANUNCIANTE(S), seja ela direta ou indireta. Assim, o SITE não é responsável por qualquer
      ação ou omissão do USUÁRIO baseada nas informações, anúncios, fotos, vídeos ou outros materiais veiculados no
      PORTAL CAMPOS BELOS. Adicionalmente, o SITE empenha seus melhores esforços para manter o PORTAL CAMPOS BELOS
      sempre atualizado, preciso e completo, mas não se responsabiliza por imprecisão, erro, fraude, inexatidão, quebra
      de patente, quebra da propriedade intelectual ou divergência nos dados, de fotos e vídeos ou outros materiais
      relacionados a anúncios ou à imprecisão das informações aqui contidas.</p>
    <p>
      Por isto, nos casos em que um ou mais USUÁRIOS ou algum terceiro inicie qualquer tipo de reclamação ou ação legal
      contra um ou mais ANUNCIANTE(S), todos os envolvidos nas reclamações ou ações devem eximir de toda
      responsabilidade o SITE e seus funcionários, agentes, operários, representantes e procuradores.
    </p>
    <p>O SITE não se responsabiliza por informações de qualquer fonte, espécie ou formato, sejam aquelas provenientes do
      PORTAL CAMPOS BELOS ou não, publicadas ou veiculadas em websites de terceiros ou em qualquer outro veículo ou
      mídia.</p>
    <p>O SITE também não se responsabiliza pelas obrigações tributárias que recaiam nas atividades entre USUÁRIO e
      ANUNCIANTE(S) do PORTAL CAMPOS BELOS.</p>
    <p>Assim, ao adquirir algum bem ou serviço, o consumidor deverá exigir nota fiscal do vendedor, a menos que este
      esteja legalmente dispensado de fornecê-la.</p>
    <p>Recomenda-se que na Internet toda transação seja realizada com cautela e bom senso redobrados. O USUÁRIO deverá
      avaliar os riscos das negociações, levando em consideração que pode estar, eventualmente, lidando com menores de
      idade ou pessoas valendo-se de falsas identidades.</p>
    <p>O SITE poderá suspender, inabilitar definitivamente e aplicar as medidas jurídicas cabíveis aos USUÁRIOS que
      utilizarem esta ferramenta com a finalidade de promover seus produtos ou serviços à venda ou com qualquer outro
      fim, e que forem denunciados pelas pessoas que receberam essas mensagens.</p>
    <p>Para fins de obtenção de informações sobre um anúncio, o USUÁRIO do PORTAL CAMPOS BELOS não terá que desembolsar
      qualquer taxa para o(s) ANUNCIANTE(S), a qualquer título que seja. Qualquer infração nesse sentido por parte de
      ANUNCIANTE(S) deve ser notificada imediatamente ao SITE.</p>
  </mat-card-content>

  <br>

  <mat-card-title style="font-size: 20px">PROPRIEDADE INTELECTUAL, DIREITOS AUTORAIS E MARCAS</mat-card-title>

  <br>
  <br>
  <mat-card-content>
    <p>
      Todo o material do PORTAL CAMPOS BELOS (a sua apresentação e “layout”, marcas, logotipos, produtos, sistemas,
      denominações de serviços e outros materiais), incluindo programas, bases de dados, imagens, arquivos ou materiais
      de qualquer outra espécie e que têm contratualmente autorizadas as suas veiculações neste portal, é protegido pela
      legislação de Propriedade Intelectual, sendo de titularidade do SITE, suas afiliadas, fornecedores ou clientes. A
      reprodução, distribuição e transmissão de tais materiais não são permitidas sem o expresso consentimento por
      escrito do SITE ou do respectivo titular, especialmente para fim comercial ou econômico.
    </p>
    <p>
      O uso indevido de materiais protegidos por propriedade intelectual (direito autoral, marcas comerciais, patentes
      etc.) apresentados no PORTAL CAMPOS BELOS será caracterizado como infração da legislação pertinente, sujeitando o
      infrator às ações judiciais cabíveis e dando ensejo à respectiva indenização aos prejudicados, seja ao SITE, seja
      a terceiros, sem prejuízo de perdas e danos e honorários advocatícios.
    </p>
  </mat-card-content>
</div>

