import {Component, OnInit} from '@angular/core';
import {GuiaComercial} from './guia-comercial';
import {CadastroService} from './cadastro.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {MatOption} from '@angular/material/core';
import {AngularFireStorage} from '@angular/fire/storage';
import {Observable} from 'rxjs';
import {finalize, map} from 'rxjs/operators';


export interface Estado {
  nome: string;
  id: number;
  sigla: string;
}

export interface Plano {
  nome: string;
  id: number;
  valor: string;
}

export interface Cidade {
  nome: string;
  id: number;
}

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {
  // files: Observable<any[]>;
  private file: any;
  constructor(private router: Router, private formBuilder: FormBuilder, private cadastroService: CadastroService,
              private storage: AngularFireStorage) {
    // this.files = this.storage.ref('empresas').listAll().pipe(
    //   map(res => res.items)
    // );
  }

  categorias: string[] = [
    'Academias',
    'Açaí',
    'Acessórios e Bijuterias',
    'Açougues',
    'Acupuntura',
    'Advogados',
    'Agropecuárias',
    'Água – Distribuidores',
    'Alarmes',
    'Alimentos Naturais e Integrais',
    'Amortecedores',
    'Antenas',
    'Ar-Condicionado',
    'Arquitetos',
    'Artesanatos',
    'Artigos p/Festas',
    'Artigos Religiosos',
    'Árvores Frutíferas',
    'Assistências Técnicas',
    'Auto-Elétricas',
    'Autoescolas',
    'Automóveis',
    'Autopeças',
    'Aviamentos',
    'Bancos',
    'Bares',
    'Bebidas – Distribuidores',
    'Bijuterias e Acessórios',
    'Bolos',
    'Bordados',
    'Boutiques',
    'Box p/Banheiros',
    'Bronzeamento',
    'Cabeleireiros',
    'Calçados',
    'Cama, Mesa e Banho',
    'Campings',
    'Carpintarias',
    'Cartórios',
    'Casa de Carnes',
    'Celulares',
    'Cerâmicas',
    'Certificado Digital',
    'CFC',
    'Chaveiros',
    'Chocolates',
    'Churrascarias',
    'Clínicas Médicas',
    'Clubes e Balneários',
    'Colchões',
    'Colégios',
    'Concessionarias de Automóveis',
    'Concreto',
    'Confecções',
    'Confeitarias',
    'Construtoras',
    'Contabilidade',
    'Cooperativas e Sindicatos',
    'Corretores imobiliários',
    'Cortinas',
    'Cosméticos',
    'Costureiras',
    'Creches',
    'Cursos',
    'Decorações',
    'Dentistas',
    'Depilação',
    'Descartáveis',
    'Despachantes',
    'Distribuidoras de Bebidas',
    'Divisórias',
    'Doces',
    'Drogarias',
    'Eletrodomésticos',
    'Eletrônica',
    'Embalagens',
    'Empadas',
    'Empréstimos',
    'Energia Solar',
    'Engenharia',
    'Escolas',
    'Escolas de Futebol',
    'Escolas de Idiomas',
    'Esmalterias',
    'Estéticas',
    'Eventos',
    'Farinhas – Polvilhos',
    'Farmácias',
    'Farmácias de Manipulação',
    'Ferragens',
    'Ferramentas',
    'Festas – Organização',
    'Fisioterapia',
    'Florais de Bach',
    'Floriculturas',
    'Fonoaudiologia',
    'Fórum',
    'Forros',
    'Fotógrafos',
    'Frigoríficos',
    'Frios e Laticínios',
    'Funerárias',
    'Gás – Distribuidores',
    'Gelo',
    'Geologia – Empresas',
    'Gesso',
    'Gráficas',
    'Guias de Turismo',
    'Guinchos',
    'Hamburguerias',
    'Hortifruti – Varejão',
    'Hospitais',
    'Hotéis',
    'Igrejas',
    'Imóveis – Locação',
    'Implantes Dentários',
    'Informática',
    'Internet',
    'Jantinhas',
    'Kitnets',
    'Laboratórios',
    'Lanchonetes',
    'Licenciamento Ambiental',
    'Lingeries',
    'Livrarias',
    'Locadoras de Veículos',
    'Lotéricas',
    'Madeiras',
    'Maquiagens',
    'Marcenarias',
    'Marmitarias',
    'Mármores e Granitos',
    'Materiais de Construção',
    'Mecânicas',
    'Médicos',
    'Mel',
    'Moda Infantojuvenil',
    'Modas e Acessórios',
    'Moto Peças',
    'Moto Táxis',
    'Motos',
    'Móveis',
    'Móveis Planejados',
    'Muay-Thai',
    'Mudas e Plantas',
    'Nutricionistas',
    'Óleos Essenciais',
    'Ópticas',
    'Padarias',
    'Pamonharias',
    'Papelarias',
    'Pastelarias',
    'Pedreiros',
    'Perfumarias',
    'Personal Trainer',
    'Pet Shops',
    'Pilates',
    'Piscinas',
    'Pizzarias',
    'Pneus',
    'Podologia',
    'Poços Artesianos',
    'Postos de Combustíveis',
    'Pousadas',
    'Presentes',
    'Produtos Veganos',
    'Rações',
    'Radiologia Médica',
    'Radiologia Odontológica',
    'Rádios',
    'Refrigeração',
    'Restaurantes',
    'Roupas',
    'Salgados',
    'Segurança',
    'Serralherias',
    'Serviços Gerais',
    'Sex Shop',
    'Som',
    'Som Automotivo',
    'Sorveterias',
    'Supermercados',
    'Suplementos Alimentares',
    'Suspensão',
    'Tapeçarias',
    'Tapiocas',
    'Táxis',
    'Tecidos',
    'Temperos',
    'Terapias Naturais',
    'Tintas',
    'Toldos',
    'Tomografia',
    'Turismo',
    'Ultrassonografia',
    'Universidades',
    'Varejão – Hortifruti',
    'Veterinários',
    'Vidraçarias',
    'Viveiros'
  ];

  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  guiaForm: FormGroup;
  estados: Estado[];
  cidades: Cidade[];
  planos: Plano[] = [
    {
      id: 1,
      nome: 'Free**',
      valor: 'Grátis',
    },
    {
      id: 2,
      nome: 'Prata**',
      valor: 'R$ 29,99'
    },
    {
      id: 3,
      nome: 'Ouro**',
      valor: 'R$ 49,99',
    }
  ];

  flag: boolean;
  mySelections: string[];

  ngOnInit(): void {

    this.cadastroService.getAllEstados().subscribe(value => {

      this.estados = value.sort((a, b) => a.nome.localeCompare(b.nome));
    });
    this.guiaForm = this.formBuilder.group({
      razaoSocial: ['', Validators.required],
      responsavel: [''],
      email: [''],
      telefone: [''],
      uf: ['', Validators.required],
      municipio: ['', Validators.required],
      logradouro: [''],
      numero: [''],
      complemento: [''],
      bairro: [''],
      plano: ['', Validators.required],
      categorias: [[], Validators.required]
    });
  }

  cadastrar(): void {
    const req: GuiaComercial = {
      ...this.guiaForm.value,
      status: true
    };
    console.log(req);

    this.cadastroService.createPro(req);

    this.dawloadFile(req.razaoSocial);

    this.flag = true;

  }


  buscarCidades(): void {
    this.guiaForm.value.municipio = '';
    this.cadastroService.getAllCidadeEstados(this.guiaForm.value.uf).subscribe(value => {
      this.cidades = value;
    });
  }

  cancelar(): void {
    this.router.navigate(['/empresas']);
  }


  changed($event: MatSelectChange): void {
    if (this.guiaForm.value.categorias.length < 6) {
      this.mySelections = this.guiaForm.value.categorias;
    } else {
      const select: MatSelect = $event.source;
      this.cadastroService.showMessage('É possível vincular apenas 5 categorias.');
      this.guiaForm.value.categorias = this.mySelections;
      select.options.forEach((item: MatOption) => item.deselect());
      select.close();
    }
  }

  // tslint:disable-next-line:typedef
  async  uploadFile(event) {
    console.log('starting UPLOAD ========');
    if (!event.target.files[0].type.startsWith('image/')) {
      this.cadastroService.showMessage('Somente imagens são permitidas');
      event.target.value = null;
      return;
    }else{
      this.file = event.target.files[0];
    }

    // Continua o processo de upload

  }

  async dawloadFile(razaoSocial: string): Promise<void> {
    const path = '/empresas/' + razaoSocial;
    await this.storage.upload(path, this.file);
    const setURL = await this.storage.ref(path).getDownloadURL();
    console.log(`====> setURL is ${setURL} <=======`);
  }

  teste(): void {
    console.log(this.guiaForm.value.file);
  }
}

