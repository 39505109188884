import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {InfoGuia} from './InfoGuia';
import * as htmlToImage from 'html-to-image';
import {toPng, toJpeg, toBlob, toPixelData, toSvg} from 'html-to-image';
import {GuiaComercialService} from './guia-comercial.service';


export interface Letra {
  letra: string;
  listaCategoria: string[];
}


@Component({
  selector: 'app-guia-comercial',
  templateUrl: './guia-comercial.component.html',
  styleUrls: ['./guia-comercial.component.scss']
})
export class GuiaComercialComponent implements OnInit {

  @Input() letras = [
    {
      letra: 'A',
      isSelecionado: false
    }, {
      letra: 'B',
      isSelecionado: false
    }, {
      letra: 'C',
      isSelecionado: false
    }, {
      letra: 'D',
      isSelecionado: false
    }, {
      letra: 'E',
      isSelecionado: false
    }, {
      letra: 'F',
      isSelecionado: false
    }, {
      letra: 'G',
      isSelecionado: false
    }, {
      letra: 'H',
      isSelecionado: false
    }, {
      letra: 'I',
      isSelecionado: false
    }, {
      letra: 'J',
      isSelecionado: false
    }, {
      letra: 'K',
      isSelecionado: false
    }, {
      letra: 'L',
      isSelecionado: false
    }, {
      letra: 'M',
      isSelecionado: false
    }, {
      letra: 'N',
      isSelecionado: false
    }, {
      letra: 'O',
      isSelecionado: false
    }, {
      letra: 'P',
      isSelecionado: false
    }, {
      letra: 'Q',
      isSelecionado: false
    }, {
      letra: 'R',
      isSelecionado: false
    }, {
      letra: 'S',
      isSelecionado: false
    }, {
      letra: 'T',
      isSelecionado: false
    }, {
      letra: 'U',
      isSelecionado: false
    }, {
      letra: 'V',
      isSelecionado: false
    }, {
      letra: 'X',
      isSelecionado: false
    }, {
      letra: 'Y',
      isSelecionado: false
    }, {
      letra: 'W',
      isSelecionado: false
    }, {
      letra: 'Z',
      isSelecionado: false
    }
  ];

  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  isMostraBusca = true;
  flag = true;
  mostrarCategoria = false;
  textoBusca = '';

  todosEnderecos: InfoGuia[] = [];

  pesquisaEnderecos: InfoGuia[] = [];

  pricipaisEnderecos: InfoGuia[] = [
    {
      id: 1,
      endereco: 'RUA TEMISTOCLES ROCHA, QUADRA O, LOTE 16A, CENTRO - CAMPOS BELOS - GO',
      imagem: 'https://i.ibb.co/gwQmNbX/18.png',
      selo: 'ouro',
      nome: 'Center Carnes',
      email: 'centercarnes@gmail.com',
      termos: '#carnes',
      telefone: '015999420876',
      plano: 3,
      categoria: 'Casa de Carnes'
    },
    {
      id: 2,
      endereco: 'R. Rui Barbosa, 658-788, Campos Belos - GO, 73840-000',
      imagem: 'assets/imagens/clientes/academia/capa-logo.png',
      nome: 'ALEX SANTANA ACADEMIA',
      email: '',
      plano: 2,
      termos: '#academia#fitness#treino#gym#foco#dieta#fit#saude#musculação',
      telefone: '015999420876',
      categoria: 'Academias',
      selo: 'ouro',
    },
    {
      id: 3,
      endereco: 'RUA TEMISTOCLES ROCHA, QUADRA 10, LOTE 24, SETOR AEROPORTO - CAMPOS BELOS - GO',
      imagem: 'assets/imagens/clientes/capa-sem-logo.png',
      plano: 1,
      telefone: '015998395236',
      nome: 'PORTAL CAMPOS BELOS',
      selo: 'ouro',
    }
  ];


  alfabeto: Letra[] = [
    {
      letra: 'A',
      listaCategoria: [
        'Academias',
        'Açaí',
        'Acessórios e Bijuterias',
        'Açougues',
        'Advogados',
        'Agências de Turismo',
        'Agropecuárias',
        'Água – Distribuidores',
        'Alarmes',
        'Alimentos Naturais e Integrais',
        'Amortecedores',
        'Antenas',
        'Ar-Condicionado',
        'Armarinhos e Tecidos',
        'Arquitetos',
        'Artesanatos',
        'Artigos p. Festas',
        'Árvores Frutíferas',
        'Assistências Técnicas',
        'Auto-Elétricas',
        'Auto Escolas',
        'Automóveis',
        'Autopeças',
        'Aviamentos',
      ]
    },
    {
      letra: 'B',
      listaCategoria: [

        'Bancos',
        'Bares',
        'Bijuterias e Acessórios',
        'Bicicletarias',
        'Bolos',
        'Bordados',
        'Boutiques',
        'Box p/Banheiros',
        'Bronzeamento',
      ]
    },
    {
      letra: 'C',
      listaCategoria: [
        'Cabeleireiros',
        'Calçados',
        'Cama, Mesa e Banho',
        'Campings',
        'Carpintarias',
        'Cartórios',
        'Casa de Carnes',
        'Celulares',
        'Cerâmicas',
        'Certificado Digital',
        'CFC',
        'Chaveiros',
        'Chocolates',
        'Churrascarias',
        'Clínicas Médicas',
        'Clubes e Balneários',
        'Colchões',
        'Colégios',
        'Concessionarias de Automóveis',
        'Concreto',
        'Confecções',
        'Confeitarias',
        'Construtoras',
        'Contabilidade',
        'Cooperativas e Sindicatos',
        'Corretores imobiliários',
        'Cortinas',
        'Cosméticos',
        'Costureiras',
        'Creches',
        'Cursos',
      ]
    },
    {
      letra: 'D',
      listaCategoria: [
        'Decorações',
        'Dentistas',
        'Depilação',
        'Descartáveis',
        'Despachantes',
        'Distribuidoras de Bebidas',
        'Divisórias',
        'Doces',
        'Drogarias',
      ]
    },
    {
      letra: 'E',
      listaCategoria: [
        'Eletrodomésticos',
        'Eletrônica',
        'Embalagens',
        'Empadas',
        'Empréstimos',
        'Energia Solar',
        'Engenharia',
        'Escolas',
        'Escolas de Futebol',
        'Escolas de Idiomas',
        'Esmalterias',
        'Estéticas',
        'Eventos',
      ]
    },
    {
      letra: 'F',
      listaCategoria: [

        'Farinhas – Polvilhos',
        'Farmácias',
        'Farmácias de Manipulação',
        'Ferragens',
        'Ferramentas',
        'Festas – Organização',
        'Fisioterapia',
        'Florais de Bach',
        'Floriculturas',
        'Fonoaudiologia',
        'Fórum',
        'Forros',
        'Fotógrafos',
        'Frigoríficos',
        'Frios e Laticínios',
        'Funerárias',
      ]
    },
    {
      letra: 'G',
      listaCategoria: [

        'Gás – Distribuidores',
        'Gelo',
        'Geologia – Empresas',
        'Gesso',
        'Gráficas',
        'Guias de Turismo',
        'Guinchos',
      ]
    },
    {
      letra: 'H',
      listaCategoria: [

        'Hamburguerias',
        'Hortifruti – Varejão',
        'Hospitais',
        'Hotéis',
      ]
    },
    {
      letra: 'I',
      listaCategoria: [

        'Igrejas',
        'Imóveis – Locação',
        'Implantes Dentários',
        'Informática',
        'Internet',
      ]
    },
    {
      letra: 'J',
      listaCategoria: [
        'Jantinhas',
      ]
    },
    {
      letra: 'K',
      listaCategoria: [
        'Kitnets',
      ]
    },
    {
      letra: 'L',
      listaCategoria: [

        'Laboratórios',
        'Lanchonetes',
        'Licenciamento Ambiental',
        'Lingeries',
        'Livrarias',
        'Locadoras de Veículos',
        'Lotéricas',
      ]
    },
    {
      letra: 'M',
      listaCategoria: [

        'Madeiras',
        'Maquiagens',
        'Marcenarias',
        'Marmitarias',
        'Mármores e Granitos',
        'Materiais de Construção',
        'Mecânicas',
        'Médicos',
        'Mel',
        'Moda Infantojuvenil',
        'Modas e Acessórios',
        'Moto Peças',
        'Moto Táxis',
        'Motos',
        'Móveis',
        'Móveis Planejados',
        'Muay-Thai',
        'Mudas e Plantas',
      ]
    },
    {
      letra: 'N',
      listaCategoria: [
        'Nutricionistas',
      ]
    },
    {
      letra: 'O',
      listaCategoria: [
        'Óleos Essenciais',
        'Ópticas',
      ]
    },
    {
      letra: 'P',
      listaCategoria: [
        'Padarias',
        'Pamonharias',
        'Papelarias',
        'Pastelarias',
        'Pedreiros',
        'Perfumarias',
        'Personal Trainer',
        'Pet Shops',
        'Pilates',
        'Piscinas',
        'Pizzarias',
        'Pneus',
        'Podologia',
        'Poços Artesianos',
        'Postos de Combustíveis',
        'Pousadas',
        'Presentes',
        'Produtos Veganos',
      ]
    }, {
      letra: 'Q',
      listaCategoria: []
    }, {
      letra: 'R',
      listaCategoria: [
        'Rações',
        'Radiologia Médica',
        'Radiologia Odontológica',
        'Rádios',
        'Refrigeração',
        'Restaurantes',
        'Roupas'
      ]
    }, {
      letra: 'S',
      listaCategoria: [

        'Salgados',
        'Segurança',
        'Serralherias',
        'Serviços Gerais',
        'Sex Shop',
        'Som',
        'Som Automotivo',
        'Sorveterias',
        'Supermercados',
        'Suplementos Alimentares',
        'Suspensão',
      ]
    }, {
      letra: 'T',
      listaCategoria: [

        'Tapeçarias',
        'Tapiocas',
        'Táxis',
        'Tecidos',
        'Temperos',
        'Terapias Naturais',
        'Tintas',
        'Toldos',
        'Tomografia',
        'Turismo',
      ]
    }, {
      letra: 'U',
      listaCategoria: [
        'Ultrassonografia',
        'Universidades',
      ]
    }, {
      letra: 'V',
      listaCategoria: [
        'Varejão – Hortifruti',
        'Veterinários',
        'Vidraçarias',
        'Viveiros',
      ]
    }, {
      letra: 'X',
      listaCategoria: []
    }, {
      letra: 'W',
      listaCategoria: []
    }, {
      letra: 'Y',
      listaCategoria: []
    }, {
      letra: 'Z',
      listaCategoria: []
    }
  ];

  letraSelecionada: Letra = {
    letra: '-1',
    listaCategoria: []
  };


  constructor(@Inject(DOCUMENT) documents: Document, private route: ActivatedRoute, private router: Router, private infoGuia: GuiaComercialService) {

    const pesquisa = this.route.snapshot.paramMap.get('pesquisa');
    if (pesquisa) {
      this.textoBusca = pesquisa;
      this.pesquisar();
    } else {
      this.pesquisaEnderecos = this.todosEnderecos;
    }
  }

  ngOnInit(): void {

    this.infoGuia.listaGuiaComercialTodas.subscribe(value => {
      this.todosEnderecos = value;
      this.pesquisaEnderecos = this.todosEnderecos.filter(value1 => value1.plano  === 3 || value1.imagem !== 'assets/imagens/clientes/capa-sem-logo.png');
    });
  }

  teste(): void {

    const shareButton = document.getElementById('share-button');
    shareButton.addEventListener('click', async (event) => {
      if ('share' in navigator) {

        const response = await fetch('https://i.ibb.co/wh05DhH/caixa-eletronico-golpe-idoso.jpg');
        const blob = await response.blob();

        const filesArray = [
          new File(
            [blob],
            'meme.jpg',
            {
              type: 'image/jpeg',
              lastModified: new Date().getTime()
            }
          )
        ];

        navigator.share({
          title: 'Portal da Campos Belos',
          text: 'Filho é preso torrando aposentadoria de idoso em farra. Vítima chegou na delegacia chorando',
          url: 'https://desafio-ailos.web.app/noticias/Luto/filho-lamenta-perda-dos-pais-no-intervalo-de-uma-semana/6',
          // @ts-ignore
          files: filesArray,
        })
          .then(() => {
            console.log('Callback after sharing');
          })
          .catch(console.error);
      } else {
        console.log('provide fallback share');
      }
    });
  }

  pesquisar(): void {
    this.isMostraBusca = true;
    if (this.textoBusca) {
      this.pesquisaEnderecos = this.todosEnderecos.sort((a, b) => (a.plano < b.plano ? 1 : -1));
    } else {
      this.pesquisaEnderecos = this.todosEnderecos.filter(value => value.plano  === 3);
    }
  }

  atualizarLista(letra): void {

    this.resetarLista();

    if (letra.index > 0 || !this.isMobile) {
      if (this.isMobile) {
        letra = this.letras[letra.index - 1];
      }

      if (this.letraSelecionada.letra === letra.letra) {
        this.flag = false;
        this.letraSelecionada = {
          letra: 'destaques',
          listaCategoria: []
        };
        this.pesquisaEnderecos = this.todosEnderecos.filter(value => value.plano  === 3 || value.imagem !== 'assets/imagens/clientes/capa-sem-logo.png');
        this.textoBusca = '';
        this.mostrarCategoria = false;
        // this.router.navigate(['/guia-comercial/']);
      } else {
        letra.isSelecionado = true;
        this.letraSelecionada = this.alfabeto.filter(value => value.letra === letra.letra)[0];
        this.flag = true;
        this.mostrarCategoria = true;
      }

    } else {
      this.flag = false;
      this.letraSelecionada = {
        letra: 'destaques',
        listaCategoria: []
      };
      this.pesquisaEnderecos = this.todosEnderecos.filter(value => value.plano  === 3 || value.imagem !== 'assets/imagens/clientes/capa-sem-logo.png');
      this.textoBusca = '';
      this.mostrarCategoria = false;
      // this.router.navigate(['/guia-comercial/']);
    }

    // this.router.navigate(['/guia-comercial/']);

    // // if (letra.letra === this.letraSelecionada.letra && this.flag && this.letraSelecionada.letra !== '-1') {
    // //   this.flag = false;
    // //   this.textoBusca = '';
    // //   this.isMostraBusca = true;
    // //   this.resetarLista();
    // //   this.pesquisar();
    // //   this.router.navigate(['/guia-comercial']);
    // // } else {
    // this.flag = true;
    // this.isMostraBusca = false;
    // this.resetarLista();
    // this.textoBusca = '';
    // letra.isSelecionado = true;

    //
    // this.router.navigate(['/guia-comercial']);

  }

  resetarLista(): void {
    this.letras.forEach(value => value.isSelecionado = false);
  }

  remover_acentos_espaco(str): string {
    return str.normalize('NFD').replace(/[^a-zA-Z\s]/g, '').replace(' ', '-');
  }

  setPesquisa(str): void {
    this.resetarLista();
    this.mostrarCategoria = false;
    this.isMostraBusca = false;
    this.textoBusca = str;
    this.pesquisar();
    // this.router.navigate(['/guia-comercial/' + this.remover_acentos_espaco(str.toLowerCase())]);
  }

  mostrarEnderecosDestaques(): void {

  }

  testee(): void {
    htmlToImage.toJpeg(document.getElementById('my-node'), {quality: 0.95})
      // tslint:disable-next-line:only-arrow-functions typedef
      .then(value => {
        const link = document.createElement('a');
        link.download = 'my-image-name.jpeg';
        link.href = value;
        link.click();
      });
  }
}

