<mat-carousel
        timings="250ms ease-in"
        [autoplay]="true"
        interval="2000"
        color="accent"
        maxWidth="auto"
        [proportion]="isMobile? 25 : 20"
        slides="6"
        [loop]="true"
        [hideArrows]="false"
        [hideIndicators]="false"
        [useKeyboard]="true"
        [useMouseWheel]="false"
        orientation="ltr"
>
    <mat-carousel-slide>
        <a href="https://superfertil.agr.br/#"><img width="100%" height="100%"
                                                    src="assets/imagens/bannerprincipal/slide-s-0-1652892156.jpeg"></a>
    </mat-carousel-slide>
    <mat-carousel-slide>
        <img width="100%" height="100%"
             src="assets/imagens/bannerprincipal/Design%20sem%20nome.png">
    </mat-carousel-slide>
<!--    <mat-carousel-slide>-->
<!--        <img width="100%" height="100%"-->
<!--             src="https://smartsupermercados.com/wp-content/uploads/2022/03/banner-site-smart-institucionalPrancheta-1-copiar.jpg">-->
<!--    </mat-carousel-slide>-->
    <mat-carousel-slide>
        <img width="100%" height="100%" src="https://ifgoiano.edu.br/home/images/banners/Banner---Site.jpeg">
    </mat-carousel-slide>
</mat-carousel>
