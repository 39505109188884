<div style="padding-top: 25px; padding-bottom: 25px">
  <h1 style="font-size: 18px" [style.font-size]="isMobile? '18px' : '24px'">POLÍTICA DE PRIVACIDADE</h1>
  <mat-divider></mat-divider>
  <br>
  <br>

  <mat-card-content>
    <p>A segurança e privacidade dos dados pessoais dos usuários do <b>Portal Campos Belos</b> são prioridade para nós. Por isso, nos comprometemos a proteger suas informações pessoais e a cumprir com as leis de privacidade aplicáveis.</p>
    <p>Este documento é nossa Política de Privacidade, que descreve como coletamos, usamos, armazenamos e compartilhamos suas informações pessoais. Por favor, leia atentamente antes de fornecer qualquer informação pessoal ao <b>Portal Campos Belos</b>.</p>

    <ol>
      <li>
        Coleta de dados pessoais: O <b>Portal Campos Belos</b> pode coletar informações pessoais, como nome, endereço de e-mail, endereço postal e número de telefone, quando você se registra em nosso site, se inscreve em nossa lista de e-mails ou realiza compras em nosso site.
      </li>
      <li>
        Uso de dados pessoais: Usamos as informações pessoais que coletamos apenas para os fins para os quais foram fornecidas, incluindo, mas não se limitando a, fornecer informações sobre nossos serviços, gerenciar sua conta e comunicações com você.
      </li>
      <li>
        Armazenamento de dados pessoais: Armazenamos as informações pessoais que coletamos por um período razoável de tempo, de acordo com as leis aplicáveis.
      </li>
      <li>
        Compartilhamento de dados pessoais: Não vendemos, alugamos ou compartilhamos suas informações pessoais com terceiros, exceto quando necessário para prestar nossos serviços ou quando exigido por lei.
      </li>
      <li>
        Acesso a dados pessoais: Os usuários do <b>Portal Campos Belos</b> têm o direito de solicitar a correção de suas informações pessoais armazenadas conosco. Você pode solicitar a correção de suas informações pessoais enviando um e-mail para nossa equipe de suporte .
      </li>
      <li>
        Alterações na Política de Privacidade: O <b>Portal Campos Belos</b> se reserva o direito de alterar esta Política de Privacidade a qualquer momento. Se houver alterações significativas, avisaremos nossos usuários por e-mail ou por meio de uma notificação em nosso site.
      </li>
      <li>
        Contato: Se você tiver alguma dúvida sobre nossa Política de Privacidade, entre em contato
      </li>
    </ol>
  </mat-card-content>
</div>


