import { Component, OnInit } from '@angular/core';
import {Noticia} from '../noticias.component';
import {NoticiasService} from '../noticias.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-noticia-maislidas',
  templateUrl: './noticia-maislidas.component.html',
  styleUrls: ['./noticia-maislidas.component.scss']
})
export class NoticiaMaislidasComponent implements OnInit {

  listaNoticiaRecente: Noticia[] = [];

  constructor(private noticiaService: NoticiasService, private router: Router) { }

  ngOnInit(): void {
    this.noticiaService.listaTodasNoticias.subscribe(value => {
      this.listaNoticiaRecente = value.filter(value1 => value1.isMaisLida);
    });
  }
}
