import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-planos',
  templateUrl: './planos.component.html',
  styleUrls: ['./planos.component.scss']
})
export class PlanosComponent implements OnInit {
  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  constructor() { }

  ngOnInit(): void {
  }

}
