import {Component, Input, OnInit} from '@angular/core';
import {Noticia} from '../noticias.component';
import {Subscription, timer} from 'rxjs';
import {Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-noticia-detalhe',
  templateUrl: './noticia-detalhe.component.html',
  styleUrls: ['./noticia-detalhe.component.scss']
})
export class NoticiaDetalheComponent implements OnInit {
  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  constructor() { }


  isCarregando = false;
  quantidadeRegistro = 0;
  @Input() noticia: Noticia;
  @Input() noticiasrelacionadas: Noticia[];
  listaNoticiaCarregadas: Noticia[] = [];
  private myTimerSub: Subscription;
  ngOnInit(): void {
    this.carregarNoticias(false);
    // this.meta.updateTag({ name: 'image', content: 'https://portalcamposbelos.com/ass
    // ets/imagens/WhatsApp%20Image%202023-01-15%20at%2011.26.59.jpeg' });
    const baseUrl = window.location.protocol + '//' + window.location.hostname;
    //
    // this.metaService.updateTag( { property: 'og:image', content: this.noticia.urlImagem1 } );
    // tslint:disable-next-line:max-line-length
    // this.metaService.updateTag( { property: 'og:url', content: baseUrl + '/noticias/' + this.noticia.categoria + '/' + this.noticia.titulo + '/' + this.noticia.id } );
    // this.metaService.updateTag( { property: 'og:description', content: this.noticia.descricao } );
    // this.metaService.updateTag( { property: 'og:title', content: this.noticia.descricao } );
    // this.metaService.updateTag( { property: 'description', content: this.noticia.descricao } );
    // this.metaService.updateTag( { property: 'title', content: this.noticia.descricao } );

    // const metaElement = this.renderer.selectRootElement('meta[property="og:image"]');
    // this.renderer.setAttribute(metaElement, 'content', 'https://portalcamposbelos.com/assets/imagens/WhatsApp%20
    // Image%202023-01-15%20at%2011.26.59.jpeg');
  }

  carregarNoticias(isCarregando): void {
    if (this.quantidadeRegistro < this.noticiasrelacionadas.length) {
      this.isCarregando = isCarregando;
      this.quantidadeRegistro = this.quantidadeRegistro + 2;
      if (isCarregando) {
        const ti = timer(2000, 2000);
        this.myTimerSub = ti.subscribe(t => {
          this.isCarregando = false;
          this.listaNoticiaCarregadas = this.noticiasrelacionadas.slice(0, this.quantidadeRegistro);
        });
      } else {
        this.listaNoticiaCarregadas = this.noticiasrelacionadas.slice(0, this.quantidadeRegistro);
      }
    }
  }

}
