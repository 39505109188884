import {Component, OnInit, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery-9';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {GuiaComercialService} from '../guia-comercial.service';
import {InfoGuia} from '../InfoGuia';
import * as htmlToImage from 'html-to-image';


export interface PeriodicElement {
  dia: string;
  horas: string;
}

export interface ListaProduto {
  id: number;
  descricao: string;
  titulo: string;
  preco: number;
  imagem: string;
  quantidade: number;
  valorTotal: number;
  obs?: string;
}

export interface CategoriaCardapio {
  id: number;
  categoria: string;
  listaProduto: ListaProduto[];
}

const ELEMENT_DATA: PeriodicElement[] = [
  {dia: 'Segunda', horas: '09:00 - 21:00'},
  {dia: 'Terça', horas: '09:00 - 21:00'},
  {dia: 'Quarta', horas: '09:00 - 21:00'},
  {dia: 'Quinta', horas: '09:00 - 21:00'},
  {dia: 'Sexta', horas: '09:00 - 21:00'},
  {dia: 'Sábado', horas: '09:00 - 21:00'},
  {dia: 'Domingo', horas: 'Fechado'},
  {dia: 'Feriados', horas: 'Fechado'},
];

@Component({
  selector: 'app-guia-comercial-detalhe',
  templateUrl: './guia-comercial-detalhe.component.html',
  styleUrls: ['./guia-comercial-detalhe.component.scss']
})
export class GuiaComercialDetalheComponent implements OnInit, AfterViewInit {

  panelOpenState = false;

  formaPagamentos = ['Dinheiro', 'Cartão de crédito', 'Cartão de débito', 'PIX'];

  constructor(private router: Router, private route: ActivatedRoute, private infoGuia: GuiaComercialService) {

  }

  cardapio: CategoriaCardapio[] = [
    {
      id: 1,
      categoria: 'Hambúrguere\'s',
      listaProduto: [
        {
          id: 1,
          titulo: 'X - BACON',
          descricao: 'Hambúrguer, presunto, mussarela, milho verde, ervilha, tomate e bacon.',
          preco: 15.00,
          imagem: 'https://s3-sa-east-1.amazonaws.com/cardapiando/images/thumb/5d3ba41e1eab6.png',
          quantidade: 0,
          obs: '',
          valorTotal: 0
        }, {
          id: 2,
          titulo: 'X - AMERICANO',
          descricao: 'Presunto, mussarela, tomate, alface, ovo, bacon, pepino, palmito, milho verde, ervilha, file de frango, file de boi, hambúrguer, azeitona e batata palha, este lanche servido no prato.',
          preco: 15.80,
          imagem: 'https://s3-sa-east-1.amazonaws.com/cardapiando/images/thumb/5d3ba380b7a6e.png',
          quantidade: 0,
          obs: '',
          valorTotal: 0
        },
        {
          id: 3,
          titulo: 'ALHO COM BACON',
          descricao: 'Mussarela, molho, alecrim, bacon, alho, palmito e óleo.',
          preco: 35.80,
          imagem: 'https://s3-sa-east-1.amazonaws.com/cardapiando/images/thumb/5d3c4e47b10f2.png',
          quantidade: 0,
          obs: '',
          valorTotal: 0
        },
        {
          id: 4,
          titulo: 'ALHO COM BACON',
          descricao: 'Mussarela, molho, alecrim, bacon, alho, palmito e óleo.',
          preco: 35.80,
          imagem: 'https://s3-sa-east-1.amazonaws.com/cardapiando/images/thumb/5d3c4e47b10f2.png',
          quantidade: 0,
          obs: '',
          valorTotal: 0
        }
      ]
    },
    {
      id: 2,
      categoria: 'PIZZAS',
      listaProduto: [
        {
          id: 1,
          titulo: 'ALHO COM BACON',
          descricao: 'Mussarela, molho, alecrim, bacon, alho, palmito e óleo.',
          preco: 35.80,
          imagem: 'https://s3-sa-east-1.amazonaws.com/cardapiando/images/thumb/5d3c4e47b10f2.png',
          quantidade: 0,
          obs: '',
          valorTotal: 0
        }
      ]
    }, {
      id: 3,
      categoria: 'Bebidas',
      listaProduto: [
        {
          id: 1,
          titulo: 'HEINEKEN',
          descricao: '360 ML',
          preco: 5,
          imagem: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrKmnu8oZ2jfa0HVRsQlKQFnrs8hK-PG_5wg&usqp=CAU',
          quantidade: 0,
          obs: '',
          valorTotal: 0
        }, {
          id: 2,
          titulo: 'Cerveja Antarctica',
          descricao: 'Pilsen Lata 350ml',
          preco: 3.50,
          imagem: 'https://www.bistek.com.br/media/catalog/product/cache/15b2f1f06e1cd470c80b1f3fd7ec8301/9/9/990612.jpg',
          quantidade: 0,
          obs: '',
          valorTotal: 0
        }
      ]
    }
  ];


  infoGuiaTela: InfoGuia = {
    email: '',
    endereco: '',
    horarios: '',
    id: 0,
    imagem: '',
    linkWhatsApp: '',
    nome: '',
    selo: '',
    telefone: '',
    termos: '',
    funcionamento: ''
  };

  title = 'My first AGM project';
  lat = -13.035118701000485;
  lng = -46.77551723654181;
  zoom = 16;

  displayedColumns: string[] = ['dia', 'horas'];
  dataSource = ELEMENT_DATA;

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  height = '500px';

  carrinho: ListaProduto[] = [];

  total = 0;

  radialistas = [
    {
      nome: 'Nome Completo',
      email: 'nome@email.com',
      contato: '123456',
      foto: 'https://img.freepik.com/vetores-premium/homem-perfil-caricatura_18591-58482.jpg?w=740'
    },
    {
      nome: 'Nome Completo',
      email: 'nome@email.com',
      contato: '234567',
      foto: 'https://img.freepik.com/vetores-premium/homem-perfil-caricatura_18591-58482.jpg?w=740'
    },
    {
      nome: 'Nome Completo',
      email: 'nome@email.com',
      contato: '345678',
      foto: 'https://img.freepik.com/vetores-premium/homem-perfil-caricatura_18591-58482.jpg?w=740'
    },
    {
      nome: 'Nome Completo',
      email: 'nome@email.com',
      contato: '234567',
      foto: 'https://img.freepik.com/vetores-premium/homem-perfil-caricatura_18591-58482.jpg?w=740'
    },
  ];

  ngOnInit(): void {

    const id = this.route.snapshot.paramMap.get('id');
    console.log(id);
    this.infoGuia.listaGuiaComercialTodas.subscribe(value => {
      // @ts-ignore
      this.infoGuiaTela = value.filter(value1 => value1.id === Number(id))[0];
    });

    if (this.isMobile) {
      this.height = '250px';
    }
    this.galleryOptions = [
      {
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        imageAutoPlay: true,
        imageAutoPlayPauseOnHover: true,
        previewAutoPlay: true,
        previewAutoPlayPauseOnHover: true,
        previewDownload: true
      },
      // max-width 800
      {
        breakpoint: 800,
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.galleryImages = [
      {
        small: 'https://www.guiadasemana.com.br/contentFiles/image/opt_w1280h720/2017/06/FEA/38161_212burger.jpg',
        medium: 'https://www.guiadasemana.com.br/contentFiles/image/opt_w1280h720/2017/06/FEA/38161_212burger.jpg',
        big: 'https://www.guiadasemana.com.br/contentFiles/image/opt_w1280h720/2017/06/FEA/38161_212burger.jpg'
      },
      {
        small: 'https://www.guiadasemana.com.br/contentFiles/image/opt_w840h0/burgers.jpg',
        medium: 'https://www.guiadasemana.com.br/contentFiles/image/opt_w840h0/burgers.jpg',
        big: 'https://www.guiadasemana.com.br/contentFiles/image/opt_w840h0/burgers.jpg'
      },
      {
        small: 'https://torres.rs.gov.br/wp-content/uploads/2022/11/2017-10-16-1200x600.jpg',
        medium: 'https://torres.rs.gov.br/wp-content/uploads/2022/11/2017-10-16-1200x600.jpg',
        big: 'https://torres.rs.gov.br/wp-content/uploads/2022/11/2017-10-16-1200x600.jpg'
      }, {
        small: 'https://www.receitasnestle.com.br/sites/default/files/2022-07/7eaa474705efd3bb0d7fe1b881c4266b_pizza-integral-liquidificador-escarola-ricota-receitas-nestle_1200_600_0.jpg',
        medium: 'https://www.receitasnestle.com.br/sites/default/files/2022-07/7eaa474705efd3bb0d7fe1b881c4266b_pizza-integral-liquidificador-escarola-ricota-receitas-nestle_1200_600_0.jpg',
        big: 'https://www.receitasnestle.com.br/sites/default/files/2022-07/7eaa474705efd3bb0d7fe1b881c4266b_pizza-integral-liquidificador-escarola-ricota-receitas-nestle_1200_600_0.jpg'
      }, {
        small: 'https://casaeconstrucao.org/wp-content/uploads/2017/04/decoracao-de-lanchonete-8.jpg',
        medium: 'https://casaeconstrucao.org/wp-content/uploads/2017/04/decoracao-de-lanchonete-8.jpg',
        big: 'https://casaeconstrucao.org/wp-content/uploads/2017/04/decoracao-de-lanchonete-8.jpg'
      }
    ];
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  addProduto(item: CategoriaCardapio, p: ListaProduto): void {

    const res = item.listaProduto.filter(value => value.id === p.id)[0];
    const update = this.carrinho.filter(value => value.id === p.id && value.titulo === p.titulo)[0];

    if (update) {
      update.quantidade = res.quantidade + 1;
      update.valorTotal = res.preco * res.quantidade;
    } else {
      res.quantidade = res.quantidade + 1;
      res.valorTotal = res.preco * res.quantidade;
      this.carrinho.push(res);
    }

    // tslint:disable-next-line:no-shadowed-variable
    this.total = this.carrinho.reduce((sum, current) => sum + current.valorTotal, 0);


  }

  testee(): void {
    htmlToImage.toJpeg(document.getElementById('mynode'), { quality: 100 })
      // tslint:disable-next-line:only-arrow-functions typedef
      .then(value => {
        const link = document.createElement('a');
        link.download = 'my-image-name.jpeg';
        link.href = value;
        link.click();
      });
  }
}


// this.galleryImages = [
//   {
//     small: 'https://shopinporto.porto.pt/wp-content/uploads/2019/07/talhos-primor-av-rodrigues-freitas_46_300A1869-Edit.jpg',
//     medium: 'https://shopinporto.porto.pt/wp-content/uploads/2019/07/talhos-primor-av-rodrigues-freitas_46_300A1869-Edit.jpg',
//     big: 'https://shopinporto.porto.pt/wp-content/uploads/2019/07/talhos-primor-av-rodrigues-freitas_46_300A1869-Edit.jpg'
//   },
//   {
//     small: 'https://shopinporto.porto.pt/wp-content/uploads/2019/07/talhos-primor-av-rodrigues-freitas_46_300A1869-Edit.jpg',
//     medium: 'https://shopinporto.porto.pt/wp-content/uploads/2019/07/talhos-primor-av-rodrigues-freitas_46_300A1869-Edit.jpg',
//     big: 'https://shopinporto.porto.pt/wp-content/uploads/2019/07/talhos-primor-av-rodrigues-freitas_46_300A1869-Edit.jpg'
//   },
//   {
//     small: 'http://talhosprimor.pt/tp/wp-content/uploads/2014/04/Loja3.jpg',
//     medium: 'http://talhosprimor.pt/tp/wp-content/uploads/2014/04/Loja3.jpg',
//     big: 'http://talhosprimor.pt/tp/wp-content/uploads/2014/04/Loja3.jpg'
//   },
//   {
//     small: 'http://talhosprimor.pt/tp/wp-content/uploads/2018/06/34525338_10211208060347002_4186997985751597056_n.jpg',
//     medium: 'http://talhosprimor.pt/tp/wp-content/uploads/2018/06/34525338_10211208060347002_4186997985751597056_n.jpg',
//     big: 'http://talhosprimor.pt/tp/wp-content/uploads/2018/06/34525338_10211208060347002_4186997985751597056_n.jpg'
//   },
//   {
//     small: 'http://talhosprimor.pt/tp/wp-content/uploads/2018/06/34525338_10211208060347002_4186997985751597056_n.jpg',
//     medium: 'http://talhosprimor.pt/tp/wp-content/uploads/2018/06/34525338_10211208060347002_4186997985751597056_n.jpg',
//     big: 'http://talhosprimor.pt/tp/wp-content/uploads/2018/06/34525338_10211208060347002_4186997985751597056_n.jpg'
//   }
// ];
