<mat-carousel
  timings="250ms ease-in"
  [autoplay]="true"
  interval="2500"
  maxWidth="auto"
  proportion="67"
  slides="5"
  [loop]="true"
  orientation="ltr">
  <mat-carousel-slide *ngFor="let item of noticiasPrincipais">
      <a
        href="/noticias/{{item.categoria + '/' + item.titulo + '/' + item.id}}"
        title="{{item.descricao}}"
        class="news-home--featured">
        <div class="featured-home zoom-img">
          <img
            src="{{item.urlImagem1}}"
            alt="{{item.descricao}}"
            class="responsive-img loaded" data-was-processed="true">
          <div class="overlay-legend">
          <span class="text-lg green-text no-margin uppercase">
            <strong style="padding: 5px">{{item.categoria}}</strong>
          </span>
            <p class="no-margin text-xxl" style="padding: 5px;">
              <strong style="line-height: 20px" [style.font-size]="isMobile? '20px' : '28px'"
                      [style.line-height]="isMobile? '20px' : '33px'">{{item.descricao}}</strong>
            </p>
          </div>
        </div>
      </a>
  </mat-carousel-slide>
</mat-carousel>
