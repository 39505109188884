import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DefaultComponent} from './layouts/default/default.component';
import {HomeComponent} from './modules/home/home.component';
import {GuiaComercialComponent} from './modules/guia-comercial/guia-comercial.component';
import {GuiaComercialDetalheComponent} from './modules/guia-comercial/guia-comercial-detalhe/guia-comercial-detalhe.component';
import {NoticiasComponent} from './modules/noticias/noticias.component';
import {NoticiaListaComponent} from './modules/noticias/noticia-lista/noticia-lista.component';
import {TermosCondicoesComponent} from './modules/termos-condicoes/termos-condicoes.component';
import {PlanosComponent} from './modules/planos/planos.component';
import {CadastroComponent} from './modules/planos/cadastro/cadastro.component';
import {RadioComponent} from './modules/radio/radio.component';
import {PoliticaPrivacidadeComponent} from './modules/politica-privacidade/politica-privacidade.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultComponent,
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'guia-comercial',
        component: GuiaComercialComponent
      },
      {
        path: 'guia-comercial/:pesquisa',
        component: GuiaComercialComponent
      },
      {
        path: 'noticias/:categoria/:titulo/:id',
        component: NoticiasComponent
      },
      {
        path: 'guia-comercial/detalhe/:id/:descricao',
        component: GuiaComercialDetalheComponent
      },
      {
        path: 'noticias',
        component: NoticiaListaComponent
      },
      {
        path: 'termos-e-condicoes',
        component: TermosCondicoesComponent
      },
      {
        path: 'planos',
        component: PlanosComponent
      },
      {
        path: 'planos/cadastro',
        component: CadastroComponent
      },
      {
        path: 'radio',
        component: RadioComponent
      },
      {
        path: 'politica-de-privacidade',
        component: PoliticaPrivacidadeComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
