<div style="padding-top: 25px; padding-bottom: 25px" *ngIf="listaNoticiaRecente.length > 0">
  <div>
    <mat-card-content>
      <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">
            <mat-card-title style="margin-top: 10px;font-size: 28px;">Notícias recentes</mat-card-title>
            <div class="base">
              <div class="pBar"></div>
            </div>
          </div>
          <div fxFlex="33%" fxFlex.xs="50%" fxFlex.sm="50%" fxFlex.md="50%" *ngFor="let item of listaNoticiaRecente">
            <a
              href="/noticias/{{item.categoria + '/' + item.titulo + '/' + item.id}}"
              class="other-news--home small-flex flex-wrap" title="{{item.descricao}}">

              <div class="news-small zoom-img no-margin">
                <img style="max-width: 280px; max-height: 180px"
                  [src]="item.urlImagem2"
                  class="responsive-img loaded" alt="{{item.descricao}}"
                  data-was-processed="true" data-src="{{item.urlImagem2}}">
              </div>

              <div>
                        <span class="categoria">
                                    <strong>{{item.categoria}}</strong>
                        </span>
                <p class="descricao">
                  <strong> {{item.descricao}} </strong>
                </p>
              </div>
            </a>
          </div>
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%" align="center">
            <a href="/noticias">
              <button  mat-raised-button style="width: 100%; background-color: #8dc63f; color: #fafafa; font-size: 15px">Veja mais</button>
            </a>
          </div>
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">
            <app-noticia-maislidas></app-noticia-maislidas>
          </div>
          <div  fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%" *ngIf="false">
            <div>
              <mat-card-title [style.font-size]="isMobile? '28px' : '28px'"  style="margin-top: 10px;">Imóveis em Campos Belos</mat-card-title>
              <div class="base">
                <div class="pBar"></div>
              </div>
              <br>
              <div fxLayout="row wrap" fxLayoutGap="16px grid">
                <div fxFlex="33%" fxFlex.xs="50%" fxFlex.sm="50%" fxFlex.md="50%">
                  <a
                    class="other-news--home small-flex flex-wrap" title="CAMPOS BELOS - LOT. DOM ALANO">

                    <div class="news-small zoom-img no-margin">
                      <img
                        src="https://venda-imoveis.caixa.gov.br/fotos/F844440780196121.jpg"
                        data-src="https://venda-imoveis.caixa.gov.br/fotos/F844440780196121.jpg"
                        class="responsive-img loaded" alt="CAMPOS BELOS - LOT. DOM ALANO"
                        data-was-processed="true">
                    </div>

                    <div>
                      <p class="descricao" style="line-height:20px">
                        <strong>CAMPOS BELOS - LOT. DOM ALANO</strong><br>
                        R$ 55.484,00
                      </p>
                    </div>
                  </a>
                </div>
                <div fxFlex="33%" fxFlex.xs="50%" fxFlex.sm="50%" fxFlex.md="50%">
                  <a
                    class="other-news--home small-flex flex-wrap" title="CAMPOS BELOS - VILA BAIANA">

                    <div class="news-small zoom-img no-margin">
                      <img
                        src="https://venda-imoveis.caixa.gov.br/fotos/F144440492528921.jpg"
                        data-src="https://venda-imoveis.caixa.gov.br/fotos/F144440492528921.jpg"
                        class="responsive-img loaded" alt="CAMPOS BELOS - VILA BAIANA"
                        data-was-processed="true">
                    </div>

                    <div>
                      <p class="descricao" style="line-height:20px">
                        <strong>CAMPOS BELOS - VILA BAIANA</strong><br>
                        R$ 125.006,70
                      </p>
                    </div>
                  </a>
                </div>
                <div fxFlex="33%" fxFlex.xs="50%" fxFlex.sm="50%" fxFlex.md="50%">
                  <a
                    class="other-news--home small-flex flex-wrap" title="CAMPOS BELOS - ST. BOM RETIRO">

                    <div class="news-small zoom-img no-margin">
                      <img
                        src="https://venda-imoveis.caixa.gov.br/fotos/F144440746945421.jpg"
                        data-src="https://venda-imoveis.caixa.gov.br/fotos/F144440746945421.jpg"
                        class="responsive-img loaded" alt="CAMPOS BELOS - ST. BOM RETIRO"
                        data-was-processed="true">
                    </div>

                    <div>
                      <p class="descricao" style="line-height:20px">
                        <strong>CAMPOS BELOS - ST. BOM RETIRO</strong><br>
                        R$ 112.774,37
                      </p>
                    </div>
                  </a>
                </div>
                <div fxFlex="33%" fxFlex.xs="50%" fxFlex.sm="50%" fxFlex.md="50%">
                  <a
                    class="other-news--home small-flex flex-wrap" title="CAMPOS BELOS - RES PORTAL DA SERRA">

                    <div class="news-small zoom-img no-margin">
                      <img
                        src="https://venda-imoveis.caixa.gov.br/fotos/F844440533042221.jpg"
                        data-src="https://venda-imoveis.caixa.gov.br/fotos/F844440533042221.jpg"
                        class="responsive-img loaded" alt="CAMPOS BELOS - RES PORTAL DA SERRA"
                        data-was-processed="true">
                    </div>

                    <div>
                      <p class="descricao" style="line-height:20px">
                        <strong>CAMPOS BELOS - RES PORTAL DA SERRA</strong><br>
                        R$ 66.552,15
                      </p>
                    </div>
                  </a>
                </div>
                <div fxFlex="33%" fxFlex.xs="50%" fxFlex.sm="50%" fxFlex.md="50%">
                  <a
                    class="other-news--home small-flex flex-wrap" title="CAMPOS BELOS - LOT. DOM ALANO">

                    <div class="news-small zoom-img no-margin">
                      <img
                        src="https://venda-imoveis.caixa.gov.br/fotos/F844440780196121.jpg"
                        data-src="https://venda-imoveis.caixa.gov.br/fotos/F844440780196121.jpg"
                        class="responsive-img loaded" alt="CAMPOS BELOS - LOT. DOM ALANO"
                        data-was-processed="true">
                    </div>

                    <div>
                      <p class="descricao" style="line-height:20px">
                        <strong>CAMPOS BELOS - LOT. DOM ALANO</strong><br>
                        R$ 55.484,00
                      </p>
                    </div>
                  </a>
                </div>
                <div fxFlex="33%" fxFlex.xs="50%" fxFlex.sm="50%" fxFlex.md="50%">
                  <a
                    class="other-news--home small-flex flex-wrap" title="CAMPOS BELOS - VILA BAIANA">

                    <div class="news-small zoom-img no-margin">
                      <img
                        src="https://venda-imoveis.caixa.gov.br/fotos/F144440492528921.jpg"
                        data-src="https://venda-imoveis.caixa.gov.br/fotos/F144440492528921.jpg"
                        class="responsive-img loaded" alt="CAMPOS BELOS - VILA BAIANA"
                        data-was-processed="true">
                    </div>

                    <div>
                      <p class="descricao" style="line-height:20px">
                        <strong>CAMPOS BELOS - VILA BAIANA</strong><br>
                        R$ 125.006,70
                      </p>
                    </div>
                  </a>
                </div>
                <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%" align="center">
                  <button  mat-raised-button style="width: 100%; background-color: #8dc63f; color: #fafafa; font-size: 15px">Veja mais</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </div>
</div>

