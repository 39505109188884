<div class="inner">
  <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" fxFlex.md="33%" align="center">
        <img width="100%" class="teste" *ngIf="!isMobile"
             src="assets/imagens/logo/WhatsApp_Image_2022-08-08_at_11.56.47-removebg-preview.png">
      </div>
      <div fxFlex="38%" fxFlex.xs="100%" fxFlex.sm="33%" fxFlex.md="33%" align="end" *ngIf="!isMobile">
        <img width="100%"
             src="assets/imagens/logo/previsao_detalhada.png">
      </div>
      <div fxFlex="35%" fxFlex.xs="100%" fxFlex.sm="33%" fxFlex.md="33%" *ngIf="!isMobile">
        <div style="padding: 10px; text-align: center">
          <audio controls autoplay title="Rádio Atividade Campos Belos">
<!--            <source  src="https://stm1.pluscast.com.br:7164/stream?1675131300982" type="audio/mpeg">-->
          </audio>
<!--          <audio controls autoplay title="Rádio Atividade 87.9 FM">-->
<!--            <source  src="https://stm2.xcast.com.br:8984/stream" type="audio/mpeg">-->
<!--          </audio>-->
        </div>
      </div>
    </div>
  </div>
</div>
<mat-divider *ngIf="!isMobile"></mat-divider>
<div style="padding-top: 5px"  *ngIf="!isMobile">
  <div>
    <section>
      <div style="text-align: center">
        <button mat-button routerLink="/">Home</button>
        <button mat-button routerLink="noticias">Notícias</button>
        <button mat-button routerLink="guia-comercial">Guia Comercial</button>
        <button title="Lançamento em Breve" mat-button>Agenda</button>
        <button title="Lançamento em Breve" mat-button>Empregos</button>
        <button title="Lançamento em Breve" mat-button>Imóveis</button>
        <button title="Lançamento em Breve" mat-button>Eventos</button>
        <button title="Lançamento em Breve" mat-button>Veículos</button>
<!--        <button mat-button routerLink="radio">Rádio</button>-->
        <button mat-button routerLink="planos">Planos </button>
      </div>
    </section>
  </div>
</div>
<mat-divider></mat-divider>

