import {Component, HostListener, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  sideBarOpen = false;
  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
   @Input() innerWidth = window.innerWidth;
   innerHeight = window.innerHeight;
  constructor() { }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    // tslint:disable-next-line:no-unused-expression
    // event.target.innerWidth;
    console.log(event.target.innerWidth);
    this.innerWidth = event.target.innerWidth;
  }

  ngOnInit(): void {
  }

  sideBarToggler($event: any): void {
    this.sideBarOpen = !this.sideBarOpen;
  }

  onActivate(event): void {
    // window.scroll(0, 0);
    //
    // window.scroll({
    //   top: 0,
    //   left: 0,
    //   behavior: 'smooth'
    // });
  }
}
