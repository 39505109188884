<div style="padding-top: 20px">
  <mat-card style="height: 100%">
    <mat-card-header>
      <!--        <mat-card-subtitle>Guia Comercial/detalhe</mat-card-subtitle>-->
      <mat-card-title>{{infoGuiaTela.nome.toUpperCase()}}</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <br>
    <mat-card-content>
      <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <div fxLayout="row wrap" fxLayoutGap="25px grid">
          <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="30%">
            <div style="padding-top: 20px; padding-left: 20px">
              <img style="max-height: 170px" mat-card-image [src]="infoGuiaTela.imagem2">
            </div>
          </div>
          <div fxFlex="45%" fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="70%">
            <table style="width: 100%;">
              <tr style="padding: 20px">
                <td>
                  <mat-icon> location_on</mat-icon>
                </td>
                <td><span>{{infoGuiaTela.endereco}}</span></td>
              </tr>
              <tr *ngIf="infoGuiaTela.email">
                <td>
                  <mat-icon>email</mat-icon>
                </td>
                <td><span>{{infoGuiaTela.email}}</span></td>
              </tr>
              <tr *ngIf="infoGuiaTela.telefone">
                <td>
                  <mat-icon *ngIf="infoGuiaTela.telefone">phone</mat-icon>
                </td>
                <td><a [href]="infoGuiaTela.linkWhatsApp"
                       target="_blank"><span>{{infoGuiaTela.telefone |  mask: '+00 (00) 00000-0000'}}</span></a></td>
              </tr>
              <tr *ngIf="infoGuiaTela.funcionamento">
                <td>
                  <mat-icon>watch_later</mat-icon>
                </td>
                <td><a [href]="infoGuiaTela.funcionamento" target="_blank">{{infoGuiaTela.funcionamento}}</a></td>
              </tr>
            </table>
          </div>
          <div fxFlex="10%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">
            <div style="text-align: center;">
              <audio controls autoplay title="Rádio Atividade Campos Belos">
                                <source src="http://stm2.xcast.com.br:8984/stream?1706872727329" type="audio/mpeg">
              </audio>
            </div>
          </div>
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">
            <mat-divider></mat-divider>
            <br>
            <div>
              <mat-card-title>DESCRIÇÃO</mat-card-title>
              <br>
              <mat-card-content [innerHTML]="infoGuiaTela.descricao">

              </mat-card-content>
            </div>
            <br>
            <br>
            <mat-divider></mat-divider>
          </div>
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">
            <div>
              <mat-card-title>NOSSA EQUIPE</mat-card-title>
              <br>
              <mat-card-content>

                <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
                  <!--        Policial-->
                  <div fxLayout="row wrap" fxLayoutGap="16px grid">
                    <div *ngFor="let radialista of radialistas" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%"
                         fxFlex.md="33%">
                      <mat-card>
                        <mat-card-header>
                          <div mat-card-avatar></div>
                          <mat-card-title>{{ radialista.nome }}</mat-card-title>
                          <mat-card-subtitle>{{ radialista.email }}</mat-card-subtitle>
                        </mat-card-header>
                        <img style="max-height: 200px" mat-card-image [src]="radialista.foto">
                        <mat-card-content>
                          <table style="width: 100%">
                            <tr>
                              <td style="width: 90%">
                              </td>
                              <td>
                                <svg style="width:40px;height:40px;color: #1C8C4D; padding-top: 6px"
                                     viewBox="0 0 24 24">
                                  <path fill="currentColor"
                                        d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"/>
                                </svg>
                              </td>
                              <td>
                                <img src="https://upload.wikimedia.org/wikipedia/commons/9/96/Instagram.svg"
                                     style="width:40px;height:37px;color: #1C8C4D; padding-top: 6px"
                                     viewBox="0 0 24 24"/>
                              </td>
                            </tr>
                          </table>
                        </mat-card-content>
                      </mat-card>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </div>
            <br>
            <br>
            <mat-divider></mat-divider>
          </div>
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">
            <div>
              <mat-card-title>PROGRAMAÇÃO</mat-card-title>
              <br>
              <mat-card-content>
                <mat-tab-group (selectedIndexChange)="teste($event)">
                  <mat-tab label="DOMINGO">Em breve</mat-tab>
                  <mat-tab label="SEGUNDA-FEIRA">Em breve</mat-tab>
                  <mat-tab label="TERÇA-FEIRA">Em breve</mat-tab>
                  <mat-tab label="QUARTA-FEIRA">Em breve</mat-tab>
                  <mat-tab label="QUINTA-FEIRA">Em breve</mat-tab>
                  <mat-tab label="SEXTA-FEIRA">Em breve</mat-tab>
                  <mat-tab label="SÁBADO">Em breve</mat-tab>
                </mat-tab-group>
              </mat-card-content>
<!--              <mat-card-content *ngIf="flag">-->
              <mat-card-content *ngIf="false">
                <div style="padding: 10px" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
                  <div fxLayout="row wrap" fxLayoutGap="25px grid">
                    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="30%">
                        <img src="https://jpimg.com.br/uploads/2022/02/hora-h-do-agro.png"
                             style="max-width: 250px; max-height: 170px;">
                    </div>
                    <div  fxFlex="75%" fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="70%">
                      <div>
                        <p style="font-weight: bold; font-size:18px">HORA DO AGRO</p>
                        <p>Kellen Severo traz informações e análises, reportagens e entrevistas com figuras relevantes
                          no contexto do agronegócio. As principais tendências e tudo o que há de mais importante no
                          setor, semanalmente, na Jovem Pan News.</p>
                        <p>Apresentado por: <span style="font-weight: bold">Nome do Radialista</span></p>
                        <p style="font-weight: bold; font-size: 18px">07h00</p>
                      </div>
                    </div>
                    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="30%">
                      <img src="https://jpimg.com.br/uploads/2023/01/thumb_linha_de_frente.jpg"
                           style="max-width: 250px; max-height: 170px;">
                    </div>
                    <div fxFlex="75%" fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="70%">
                      <div>
                        <p style="font-weight: bold; font-size:18px">LINHA DE FRENTE</p>
                        <p>Talk show que analisa os principais assuntos da semana de política, entretenimento e comportamento. O programa tem cinco comentaristas com opiniões diversas que contribuem para o debate.</p>
                        <p>Apresentado por: <span style="font-weight: bold">Nome do Radialista</span></p>
                        <p style="font-weight: bold; font-size: 18px">09h00</p>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </div>
            <br>
            <br>
          </div>
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%" *ngIf="false">
            <div>
              <div fxLayout="row wrap" fxLayoutGap="25px grid">
                <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
                  <div>
                    <mat-card-title>LOCALIZAÇÃO</mat-card-title>
                    <br>
                    <agm-map [latitude]="lat" [longitude]="lng" [(zoom)]="zoom">
                      <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                      <agm-fullscreen-control></agm-fullscreen-control>
                    </agm-map>
                  </div>
                </div>
                <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
                  <div style="padding-left: 20px">
                    <mat-card-title>ENTRE EM CONTATO</mat-card-title>
                    <br>
                    <mat-card-subtitle>Entre em contato com a empresa</mat-card-subtitle>
                    <mat-card-content>
                      <form class="example-form">

                        <mat-form-field class="example-full-width">
                          <mat-label>Nome</mat-label>
                          <input matInput placeholder="Ex. JOÃO DA SILVA">
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                          <mat-label>Email</mat-label>
                          <input matInput placeholder="Ex. joaosilva@gmail.com">
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                          <mat-label>Telefone</mat-label>
                          <input matInput placeholder="Ex. (62) 99999-9999">
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                          <mat-label>Mensagem</mat-label>
                          <textarea matInput [rows]="4"></textarea>
                        </mat-form-field>
                        <div style="text-align: end">
                          <button mat-raised-button
                                  style="min-width: 150px; background-color: #1C8C4D; color: #fafafa">Enviar
                          </button>
                        </div>
                      </form>
                    </mat-card-content>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br>
      </div>
    </mat-card-content>
  </mat-card>
</div>
