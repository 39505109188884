import {Component, OnDestroy, OnInit} from '@angular/core';
import {Noticia} from '../noticias.component';
import {NoticiasService} from '../noticias.service';
import {Subscription, timer} from 'rxjs';

@Component({
  selector: 'app-noticia-lista',
  templateUrl: './noticia-lista.component.html',
  styleUrls: ['./noticia-lista.component.scss']
})
export class NoticiaListaComponent implements OnInit {

  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  textoBusca = '';
  isCarregando = false;
  listaNoticias: Noticia[] = [];
  listaNoticiaCarregadas: Noticia[] = [];
  quantidadeRegistro = 0;
  private myTimerSub: Subscription;

  constructor(private noticiaService: NoticiasService) {
  }

  ngOnInit(): void {
    this.noticiaService.listaTodasNoticias.subscribe(value => {
      this.listaNoticias = value.reverse();
      this.carregarNoticias(false);
    });
  }

  carregarNoticias(isCarregando): void {
    if (this.quantidadeRegistro < this.listaNoticias.length) {
      this.isCarregando = isCarregando;
      this.quantidadeRegistro = this.quantidadeRegistro + 2;
      if (isCarregando) {
        const ti = timer(2000, 2000);
        this.myTimerSub = ti.subscribe(t => {
          this.isCarregando = false;
          this.listaNoticiaCarregadas = this.listaNoticias.slice(0, this.quantidadeRegistro);
        });
      } else {
        this.listaNoticiaCarregadas = this.listaNoticias.slice(0, this.quantidadeRegistro);
      }
    }
  }

  pesquisar(): void {
    if (this.textoBusca){
      if(this.textoBusca.length > 3){
        this.listaNoticiaCarregadas = this.listaNoticias;
      }
    }else{
      this.quantidadeRegistro = 0;
      this.carregarNoticias(false);
    }
  }

}
