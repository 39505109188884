import {Component, OnInit} from '@angular/core';
import {InfoGuia} from '../guia-comercial/InfoGuia';
import {Track} from 'ngx-audio-player/lib/model/track.model';
import {Noticia} from '../noticias/noticias.component';
import {NoticiasService} from '../noticias/noticias.service';

export interface PeriodicElement {
  programa: string;
  apresentador: string;
  horario: string;
}

const ELEMENT_DATA: PeriodicElement[] =
  [
    {programa: 'MOMENTO DE REFLEXÃO', apresentador: 'MESTRE MANOEL DE OGUN', horario: '07:30 HORAS'},
    {programa: 'CONEXÃO BRASIL', apresentador: 'LUIZ SÉRGIO', horario: '08:00 HORAS'},
    {programa: 'VOZ DA LIBERTAÇÃO', apresentador: 'IGREJA PENTECOSTAL DEUS É AMOR', horario: '11:00 HORAS'},
    {programa: 'CRISTO É A ESPERANÇA', apresentador: 'IGREJA ASSEMBLÉIA DE DEUS', horario: '12:00 HORAS'},
    {programa: 'PROGRAMA MUSICAL', apresentador: 'MUSICAL RCB AM', horario: '20:00 HORAS'},
    {programa: 'CLUBE DO OUVINTE', apresentador: 'ANDRÉ GONÇALVES', horario: '13:00 HORAS'},
    {programa: 'EDIMAR CARDOSO SHOW', apresentador: 'EDIMAR CARDOSO', horario: '16:00 HORAS'},
    {programa: 'BOM DIA', apresentador: 'HAMILTON MENDES', horario: '05:00 HORAS'}
  ];

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {

  listaNoticiaRecente: Noticia[] = [];
  flag = true;
  panelOpenState = false;
  displayedColumns: string[] = ['PROGRAMA', 'APRESENTADOR', 'HORARIO'];
  dataSource = ELEMENT_DATA;

  playlist: Track[] = [
    {
      title: 'Rádio RCB AM',
      link: 'https://stm1.pluscast.com.br:7164/stream?1675131300982'
    }
  ];

  infoGuiaTela: InfoGuia = {
    email: '',
    endereco: 'Rua 01 QD 01 LT 07 - Novo Horizonte, Campos Belos - GO, 73840-000',
    horarios: '',
    id: 0,
    imagem: 'https://img.comunidades.net/rad/radioatividade87fm/app_radio1000.png',
    imagem2: 'https://img.comunidades.net/rad/radioatividade87fm/app_radio1000.png',
    linkWhatsApp: 'https://api.whatsapp.com/send?phone=5562999996823&text=Ol%C3%A1,%20encontrei%20seu%20an%C3%BAncio%20no%20Portal%20Campos%20Belos...',
    nome: 'Rádio Atividade Campos Belos',
    selo: '',
    telefone: '+5562996960687',
    termos: '',
    funcionamento: 'http://www.atividade87fm.com.br/',
    descricao: '<p>A Rádio Atividade Campos Belos é a voz autêntica da comunidade local. Sintonize para ouvir uma programação diversificada de música brasileira vibrante, notícias locais e entretenimento regional. Nossa estação de rádio está enraizada na cultura de Campos Belos, oferecendo cobertura de eventos, entrevistas exclusivas com artistas locais e shows ao vivo que o conectam à cena musical da região. Além da música, nós o mantemos informado sobre os últimos acontecimentos na cidade e incentivamos a participação ativa dos ouvintes. Somos mais do que apenas uma estação de rádio, somos um reflexo da comunidade, uma fonte confiável de entretenimento e uma conexão com a atmosfera local de Campos Belos. Sintonize e faça parte da nossa jornada sonora.</p>'
  };

  title = 'My first AGM project';
  lat = -13.035118701000485;
  lng = -46.77551723654181;
  zoom = 16;

  // playlist = [
  //   // {
  //   //   title: 'Rádio Atividade Fm',
  //   //   link: 'https://stm2.xcast.com.br:8984/stream'
  //   // },
  //
  // ];


  audioList = [
    {
      url: 'https://stm1.pluscast.com.br:7164/stream?1675131300982',
      title: 'Rádio RCB AM',
      cover: 'https://www.rcbam.com/assets/img/logo.png'
    }];

  radialistas = [
    {
      nome: 'Nome Radialista',
      email: 'radialista@email.com',
      contato: '123456',
      foto: 'https://img.freepik.com/vetores-premium/homem-perfil-caricatura_18591-58482.jpg?w=740'
    },
    {
      nome: 'Nome Radialista',
      email: 'radialista@email.com',
      contato: '234567',
      foto: 'https://img.freepik.com/vetores-premium/homem-perfil-caricatura_18591-58482.jpg?w=740'
    },
    {
      nome: 'Nome Radialista',
      email: 'radialista@email.com',
      contato: '345678',
      foto: 'https://img.freepik.com/vetores-premium/homem-perfil-caricatura_18591-58482.jpg?w=740'
    },
    {
      nome: 'Nome Radialista',
      email: 'radialista@email.com',
      contato: '234567',
      foto: 'https://img.freepik.com/vetores-premium/homem-perfil-caricatura_18591-58482.jpg?w=740'
    },
  ];

  constructor(private noticiaService: NoticiasService) { }

  ngOnInit(): void {
    this.noticiaService.listaTodasNoticias.subscribe(value => {
      const tamanho = value.length;
      if (Number(tamanho) > 6){
        this.listaNoticiaRecente = value.filter(value1 => value1.categoria === 'cidade').reverse();
      }
    });
  }

  teste($event: number): void {
    if ($event !== 0) {
      this.flag = false;
    } else {
      this.flag = true;
    }
  }
}
