import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {GuiaComercial} from './guia-comercial';
import {Contadores} from './contadores';
import {Observable, throwError} from 'rxjs';
import {Cidade, Estado} from './cadastro.component';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CadastroService {

  private dbPath = '/guiaComercial';

  guiasRef: AngularFirestoreCollection<GuiaComercial>;

  constructor(private httpClient: HttpClient, private snackBar: MatSnackBar, private fireServices: AngularFirestore, private router:
    Router) {
    this.guiasRef = fireServices.collection(this.dbPath);
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getAll(): AngularFirestoreCollection<GuiaComercial> {
    return this.guiasRef;
  }


  // create(guiaComercial: GuiaComercial): any {
  //   return this.guiasRef.add({ ...guiaComercial });
  // }

  create(guiaComercial: GuiaComercial): any {
    return this.guiasRef.doc(guiaComercial.id.toString()).set(guiaComercial, {merge: true});
  }

  createPro(guiaComercial: GuiaComercial): any {
    this.fireServices.collection('contadores').doc('geral').get().subscribe(value => {

      if (value.exists) {
        // @ts-ignore
        guiaComercial.id = value.data().quantidadeRegistrosGuiaComercial +  1;
        this.guiasRef.doc(guiaComercial.id.toString()).set(guiaComercial, {merge: true}).then(value1 => {
          // this.showMessage('Empresa cadastr com sucesso..', true);
          this.updateContador('geral', {
            quantidadeRegistrosGuiaComercial: Number(guiaComercial.id)
          });
          this.router.navigate(['/guiaComercial']);
        });
      } else {
        this.showMessage('Não foi possível finalizar o seu cadastro.', true);
      }
    });
  }

  update(id: string, data: any): Promise<void> {
    return this.guiasRef.doc(id).update(data);
  }

  delete(id: string): Promise<void> {
    return this.guiasRef.doc(id).delete();
  }

  updateContador(id: string, data: Contadores): Promise<void> {
    return this.fireServices.collection('contadores').doc(id).update(data);
  }


  // tslint:disable-next-line:typedef
  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  showMessage(msg: string, isError: boolean = false): void {
    this.snackBar.open(msg, 'X',
      {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: isError ? ['msg-error'] : ['msg-sucess']
      }
    );
  }

  getAllEstados(): Observable<Estado[]> {
    return this.httpClient.get<Estado[]>('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getAllCidadeEstados(UF: string): Observable<Cidade[]> {
    return this.httpClient.get<Cidade[]>('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + UF + '/municipios')
      .pipe(
        catchError(this.errorHandler)
      );
  }
}
