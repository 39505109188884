import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {DefaultComponent} from './default.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatDividerModule} from '@angular/material/divider';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {HomeComponent} from '../../modules/home/home.component';
import {MatListModule} from '@angular/material/list';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatStepperModule} from '@angular/material/stepper';
import {NgxMaskModule, IConfig} from 'ngx-mask';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {HttpClientModule} from '@angular/common/http';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {_MatMenuDirectivesModule, MatMenuModule} from '@angular/material/menu';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCarouselModule} from '@ngmodule/material-carousel';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {PatrocinadoresComponent} from '../../modules/patrocinadores/patrocinadores.component';
import {NoticiasPrincpipaisComponent} from '../../modules/noticias-princpipais/noticias-princpipais.component';
import {
  BannerPrincipalPatrocinadoresComponent
} from '../../modules/banner-principal-patrocinadores/banner-principal-patrocinadores.component';
import {NoticiasRecentesComponent} from '../../modules/noticias-recentes/noticias-recentes.component';
import {NoticiasComponent} from '../../modules/noticias/noticias.component';
import {NoticiaDetalheComponent} from '../../modules/noticias/noticia-detalhe/noticia-detalhe.component';
import {NoticiaListaComponent} from '../../modules/noticias/noticia-lista/noticia-lista.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NoticiaMaislidasComponent} from '../../modules/noticias/noticia-maislidas/noticia-maislidas.component';
import {FooterComponent} from '../../modules/footer/footer/footer.component';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {MatSelectModule} from '@angular/material/select';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
import {CurrencyMaskModule} from 'ng2-currency-mask';
import localePt from '@angular/common/locales/pt';
import {CadastroComponent} from '../../modules/planos/cadastro/cadastro.component';
import {PlanosNoticiaComponent} from '../../modules/noticias/planos-noticia/planos-noticia.component';
import {RadioComponent} from '../../modules/radio/radio.component';
import {AgmCoreModule} from '@agm/core';
import {NgxAudioPlayerModule} from 'ngx-audio-player';
import {AngMusicPlayerModule} from 'ang-music-player';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';

registerLocaleData(localePt);


@NgModule({
  declarations: [
    DefaultComponent,
    HomeComponent,
    PatrocinadoresComponent,
    NoticiasPrincpipaisComponent,
    BannerPrincipalPatrocinadoresComponent,
    NoticiasRecentesComponent,
    NoticiasComponent,
    NoticiaDetalheComponent,
    NoticiaListaComponent,
    NoticiaMaislidasComponent,
    FooterComponent,
    CadastroComponent,
    PlanosNoticiaComponent,
    RadioComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MatSidenavModule,
    MatDividerModule,
    FlexLayoutModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    MatToolbarModule,
    MatCheckboxModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    MatSnackBarModule,
    _MatMenuDirectivesModule,
    MatMenuModule,
    MatGridListModule,
    MatCarouselModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    Ng2SearchPipeModule,
    CurrencyMaskModule,
    MatSelectModule,
    AgmCoreModule,
    NgxAudioPlayerModule,
    AngMusicPlayerModule,
    MatExpansionModule,
    MatChipsModule
  ],
  exports: [
    BannerPrincipalPatrocinadoresComponent,
    NoticiasRecentesComponent
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    }
  ]
})
export class DefaultModule {
}
