<div #target>
  <div style="height: 100%; padding-bottom: 25px; padding-top: 25px">

<!--    <div style="padding-bottom: 25px; padding-top: 25px">-->
<!--      <app-banner-principal-patrocinadores></app-banner-principal-patrocinadores>-->
<!--    </div>-->

    <div fxLayout="row wrap" fxLayoutGap="20px grid">

      <div fxFlex="75%" fxFlex.xs="100%" fxFlex.sm="100%">
        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
          <div fxLayout="row wrap" fxLayoutGap="16px grid">
            <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">
              <div>
                <mat-card-subtitle> <a  href="/">Home</a>  > <a href="/noticias">Notícias</a></mat-card-subtitle>
                <br>
                <mat-card-title [style.font-size]="isMobile? '25px' : '35px'">Notícias de Campos Belos</mat-card-title>
                <br>
                <form class="example-form">
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="Buscar" [(ngModel)]="textoBusca" name="textoBuscar" autocomplete="off"
                           (ngModelChange)="pesquisar()">
                    <mat-icon matSuffix>search</mat-icon>
                  </mat-form-field>
                </form>
                <mat-card-subtitle *ngIf="textoBusca">Resultados da pesquisa: {{(listaNoticiaCarregadas | filter: textoBusca)?.length}}</mat-card-subtitle>
                <br>
                <mat-card-content>
                  <div *ngFor="let item of listaNoticiaCarregadas | filter: textoBusca " [ngClass]="isMobile === true ? 'mobile' : 'desktop'">
                    <a
                      href="/noticias/{{item.categoria + '/' + item.titulo + '/' + item.id}}"
                      class="other-news--home small-flex flex-wrap" title="{{item.descricao}}">
                      <table>
                        <tr *ngIf="!isMobile">
                          <td class="tg-0lax" rowspan="4">
                            <img [src]="item.urlImagem2">
                          </td>
                          <td class="tg-0lax" colspan="3" class="categoria">
                              <strong [style.font-size]="isMobile? '11x' : '16px'">{{item.categoria}}</strong>
                          </td>
                        </tr>
                        <tr *ngIf="!isMobile">
                          <td class="tg-0pky" colspan="3" class="descricao">
                              <strong [style.font-size]="isMobile? '13.58px' : '24px'">{{item.descricao}}</strong>
                          </td>
                        </tr>
                        <tr *ngIf="!isMobile">
                          <td colspan="3" style="color: #616161; font-size: 14px"><p  [innerHTML]="item.conteudo">
                          </p>
                          </td>
                        </tr>
                        <tr *ngIf="!isMobile">
                          <td colspan="3" style="font-size: 12px; color: #212121">Publicado
                            em {{item.dataHoraPublicacao}}</td>
                        </tr>

<!--                        MOBILE-->
                        <tr *ngIf="isMobile">
                          <td>
                            <img [src]="item.urlImagem2">
                          </td>
                          <td>
                            <div class="descricao" [style.margin-top]="isMobile? '-20px': '0px'">
                              <strong class="categoria" [style.font-size]="isMobile? '11x' : '24px'">{{item.categoria}}</strong>
                              <br>
                              <strong [style.font-size]="isMobile? '13.58px' : '24px'">{{item.descricao}}</strong>
                            </div>
                            <div  style="font-size: 12px; color: #212121">{{item.dataHoraPublicacao}}
                            </div>
                          </td>
                        </tr>
                      </table>
                    </a>
                    <mat-divider style="margin-left: 16px; width: 96%"></mat-divider>
                    <br>
                  </div>
                </mat-card-content>
                <div id="outer" *ngIf="isCarregando">
                  <div id="inner"><mat-spinner color="primary"></mat-spinner></div>
                </div>
                <button *ngIf="!isCarregando && quantidadeRegistro < listaNoticias.length && !textoBusca"  (click)="carregarNoticias(true)" mat-raised-button
                        style="width: 100%; background-color: #8dc63f; color: #fafafa; font-size: 18px">Carregar mais
                  notícias
                </button>
                <br>

              </div>
              <app-noticias-recentes></app-noticias-recentes>
            </div>
          </div>
        </div>
      </div>
      <div #content fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" *ngIf="!isMobile">
        <app-patrocinadores></app-patrocinadores>
      </div>
      <br>
    </div>
  </div>
</div>
