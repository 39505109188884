<br>
<img width="100%"
     src="https://transporteslc.com.br/wp-content/uploads/2018/11/banner12.jpg"
     data-src="https://transporteslc.com.br/wp-content/uploads/2018/11/banner12.jpg">

<br>
<br>
<mat-card *ngIf="listaNoticiaRecente.length > 0">
  <mat-card-title style="margin-top: 10px;font-size: 28px;">Mais lidas</mat-card-title>
  <div class="base">
    <div class="pBar"></div>
  </div>
  <br>
  <div *ngFor="let item of listaNoticiaRecente; let i = index" routerLink="">
    <a style="color: #212121" href="/noticias/{{item.categoria + '/' + item.titulo + '/' + item.id}}"
       class="other-news--home small-flex flex-wrap" title="{{item.descricao}}">
      <table>
        <tr>
          <td rowspan="2" class="colunateste">{{i + 1}}</td>
          <td class="colunateste2"><strong>{{item.categoria}}</strong></td>
        </tr>
        <tr>
          <td> <p class="colunateste3">{{item.descricao}}</p></td>
        </tr>
        <br *ngIf="i !== listaNoticiaRecente.length -1">
        <mat-divider *ngIf="i !== listaNoticiaRecente.length -1"></mat-divider>
      </table>
    </a>
  </div>
</mat-card>
