import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {NoticiasService} from '../noticias/noticias.service';
import {Noticia} from '../noticias/noticias.component';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-noticias-princpipais',
  templateUrl: './noticias-princpipais.component.html',
  styleUrls: ['./noticias-princpipais.component.scss']
})
export class NoticiasPrincpipaisComponent implements OnInit {
  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  noticiasPrincipais: Noticia[] = [];
  constructor(private noticiaservice: NoticiasService) { }

  ngOnInit(): void {
    this.noticiaservice.listaNoticiasPrincipais.subscribe(value => {
      this.noticiasPrincipais = value;
    });
  }

}
