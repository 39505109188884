import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-planos-noticia',
  templateUrl: './planos-noticia.component.html',
  styleUrls: ['./planos-noticia.component.scss']
})
export class PlanosNoticiaComponent implements OnInit {
  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  constructor() { }

  ngOnInit(): void {
  }

}
