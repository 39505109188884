<div  style=" padding-top: 20px">
<!--  <app-banner-principal-patrocinadores></app-banner-principal-patrocinadores>-->
  <br>
  <mat-card>
    <mat-card-title [style.font-size]="isMobile? '25px' : '35px'" style="line-height: 25px">Guia Comercial de Campos
      Belos
    </mat-card-title>
    <br>
    <mat-card-subtitle>Por aqui você encontra facilmente números de telefone e endereços das empresas de nossa cidade
      ;-)
    </mat-card-subtitle>

    <mat-form-field class="example-full-width" *ngIf="!mostrarCategoria">
      <input matInput placeholder="Buscar" [(ngModel)]="textoBusca" name="textoBuscar" autocomplete="off"
             (ngModelChange)="pesquisar()">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>

    <mat-card-content style="padding-bottom: 15px">
      <mat-grid-list cols="27" rowHeight="1:1">
        <div *ngFor="let item of letras" (click)="atualizarLista(item)" >
          <mat-grid-tile *ngIf="!isMobile">
            <button mat-button [style.color]=" item.isSelecionado? '#fff' : '#1C8C4D'"
                    [style.background-color]=" item.isSelecionado? '#1C8C4D' : '#fff'"
                    style="font-size: 20px; font-weight: bold; color: #1C8C4D;">{{item.letra}}</button>
          </mat-grid-tile>
        </div>
        <mat-tab-group *ngIf="isMobile" class="pagination"  (selectedTabChange)="atualizarLista($event)">
          <mat-tab  label="Destaques" (click)="testee()"> </mat-tab>
          <mat-tab  label="{{item.letra}}"   *ngFor="let item of letras ;"> </mat-tab>
        </mat-tab-group>
      </mat-grid-list>
    </mat-card-content>


    <mat-card-content *ngIf="mostrarCategoria">
      <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <!--        Policial-->
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
          <mat-card-subtitle *ngIf="letraSelecionada.listaCategoria.length === 0">
            Nenhum resultado encontrado.
          </mat-card-subtitle>
          <div style="cursor: pointer; margin-top: -10px " fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="50%"
               *ngFor="let item of letraSelecionada.listaCategoria">
            <a (click)="setPesquisa(item)">
            <card>
              {{item}}
              <hr class="custom">
            </card>
            </a>
          </div>
        </div>
      </div>
    </mat-card-content>


    <mat-card-content *ngIf="!mostrarCategoria">
      <mat-form-field class="example-full-width" *ngIf="false">
        <input matInput placeholder="Buscar" [(ngModel)]="textoBusca" name="textoBuscar" autocomplete="off"
               (ngModelChange)="pesquisar()">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <mat-card-subtitle *ngIf="textoBusca">Resultados da
        pesquisa: {{(pesquisaEnderecos | filter: textoBusca)?.length}}</mat-card-subtitle>

      <mat-card-content *ngIf="pesquisaEnderecos.length > 0">
        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
          <!--        Policial-->
          <div fxLayout="row wrap" fxLayoutGap="16px grid">
            <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" fxFlex.md="33%"
                 *ngFor="let item of  pesquisaEnderecos | filter: textoBusca ">
              <mat-card class="example-card">
                <div style="width: 100%; position: absolute">
                  <img *ngIf="item.selo" width="35px" style="float: right; padding-right: 20px" src="../../../assets/imagens/selos/{{item.selo}}.png">
                </div>

                <img mat-card-image [src]="item.imagem"
                     alt="Photo of a Shiba Inu">
                <mat-card-content>

                  <span style="color: #1C8C4D"><b>{{item.nome.toString().toUpperCase()}}</b></span><br>
                  <p class="p">
                    {{item.endereco.toString().toUpperCase()}}
                  </p>
                </mat-card-content>
                <mat-card-actions>
                  <table style="width: 100%">
                    <tr>
                      <td style="width: 90%">
                        <button  mat-raised-button style="background-color: #1C8C4D; color: #fafafa"
                                ><a style="color: #ffffff" href="/guia-comercial/detalhe/{{item.id}}/detahe">Saiba mais</a>
                        </button>
                        <!--                        <button id="share-button" (click)="teste.json()" mat-raised-button style="background-color: #1C8C4D; color: #fafafa"-->
                        <!--                                >Share me-->
                        <!--                        </button>-->
                      </td>
                      <td *ngIf="item.telefone"><a href="https://api.whatsapp.com/send?phone={{item.telefone}}&text=Olá, encontrei seu anúncio no Portal Campos Belos...">
                        <svg style="width:40px;height:40px;color: #1C8C4D; padding-top: 6px" viewBox="0 0 24 24">
                          <path fill="currentColor"
                                d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"/>
                        </svg>
                      </a></td>
                    </tr>
                  </table>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>
          <br>
        </div>
      </mat-card-content>
    </mat-card-content>
  </mat-card>
</div>

