<div style=" padding-top: 15px">
  <!--  <app-banner-principal-patrocinadores></app-banner-principal-patrocinadores>-->
  <div>
    <div style="text-align: center">
      <mat-card-title [style.font-size]="isMobile? '25px' : '35px'" style="line-height: 25px">PLANOS
      </mat-card-title>
      <br>
      <mat-card-subtitle>Contrate um dos nossos planos e anuncie em nosso Guia Comercial.
      </mat-card-subtitle>
    </div>

    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
        <br>
        <mat-card>
          <mat-card-content style="; color: #fafafa; background-color: #1C8C4D;border-radius: 5px;">
            <div style="padding: 15px; font-size: 26px">Free**</div>
            <div style="text-align: center; background-color: #fafafa; color: #1C8C4D">
              <div style="font-size: 16px; padding: 15px">
                <span>Mensal</span>
              </div>

              <div style="font-size: 16px; padding: 15px">
                <span [style.font-size]="isMobile? '35px' :'47px'" style=" padding: 10px">Grátis</span>
              </div>

              <div style="font-size: 16px; padding: 15px">
                <span style="font-size: 16px; padding: 10px">Anuncie Gratuitamente</span>
              </div>

              <div style="font-size: 16px; padding: 15px">
                <span style="font-size: 16px; padding: 10px; font-weight: bold">SEM FIDELIDADE</span>
              </div>

              <div style="font-size: 16px; padding: 15px">
                Nome
                <hr class="custom">
                Categoria
                <hr class="custom">
                Telefone
                <hr class="custom">
                Endereço
                <hr class="custom">
                <s>Logomarca</s>
                <hr class="custom">
                <s>Descrições detalhadas da Empresa</s>
                <hr class="custom">
                <s>Galeria de fotos</s>
                <hr class="custom">
                <s>Cardápio (p/ Restaurantes) e/ou Catálogo de Produtos/Serviços</s>
                <hr class="custom">
                <s>Localização</s>
                <hr class="custom">
                <s>Compartilhamento em mídias sociais</s>
<!--                <hr class="custom">-->
<!--                <s>Comunicação diretamente dos clientes com o e-mail pelo entre em contato/fale conosco</s>-->
              <br>
              <br>
              </div>

            </div>
            <a  style="color: #fafafa" href="planos/cadastro">
              <button style="width: 100%; padding: 10px; font-size: 18px" mat-button>Assinar</button>
            </a>

          </mat-card-content>
        </mat-card>
      </div>
      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
        <mat-card>
          <mat-card-content style="; color: #fafafa; background-color: #1C8C4D;border-radius: 5px;">
            <div style="padding: 15px; font-size: 26px">Ouro**</div>
            <div style="text-align: center; background-color: #fafafa; color: #1C8C4D">
              <div style="font-size: 16px; padding: 15px">
                <span>Mensal</span>
              </div>

              <div style="font-size: 16px; padding: 15px">
                <span [style.font-size]="isMobile? '35px' :'47px'"  style=" padding: 10px">R$ 39,99</span>
              </div>

              <div style="font-size: 16px; padding: 15px">
                <span style="font-size: 16px; padding: 10px">Pagamento anual à vista R$ 399,99</span>
              </div>

              <div style="font-size: 16px; padding: 15px">
                <span style="font-size: 16px; padding: 10px; font-weight: bold">FIDELIDADE 12 MESES</span>
              </div>

              <div style="font-size: 16px; padding: 15px; padding-bottom: 35px">
                Nome
                <hr class="custom">
                Categoria
                <hr class="custom">
                Telefone
                <hr class="custom">
                Endereço
                <hr class="custom">
                Logomarca
                <hr class="custom">
                Descrições detalhadas da Empresa
                <hr class="custom">
                Galeria de fotos
                <hr class="custom">
                Cardápio (p/ Restaurantes) e/ou Catálogo de Produtos/Serviços
                <hr class="custom">
                Localização
                <hr class="custom">
                Compartilhamento em mídias sociais
<!--                <hr class="custom">-->
<!--                Comunicação diretamente dos clientes com o e-mail pelo entre em contato/fale conosco-->
              </div>
            </div>
            <a  style="color: #fafafa" href="planos/cadastro">
              <button style="width: 100%; padding: 10px; font-size: 18px" mat-button>Assinar</button>
            </a>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
        <br>
        <mat-card>
          <mat-card-content style="; color: #fafafa; background-color: #1C8C4D;border-radius: 5px;">
            <div style="padding: 15px; font-size: 26px">Prata**</div>
            <div style="text-align: center; background-color: #fafafa; color: #1C8C4D">
              <div style="font-size: 16px; padding: 15px">
                <span>Mensal</span>
              </div>

              <div style="font-size: 16px; padding: 15px">
                <span [style.font-size]="isMobile? '35px' :'47px'" style=" padding: 10px">R$ 29,99</span>
              </div>

              <div style="font-size: 16px; padding: 15px">
                <span style="font-size: 16px; padding: 10px">Pagamento anual à vista R$ 299,99</span>
              </div>

              <div style="font-size: 16px; padding: 15px">
                <span style="font-size: 16px; padding: 10px; font-weight: bold">FIDELIDADE 12 MESES</span>
              </div>

              <div style="font-size: 16px; padding: 15px">
                Nome
                <hr class="custom">
                Categoria
                <hr class="custom">
                Telefone
                <hr class="custom">
                Endereço
                <hr class="custom">
                Logomarca
                <hr class="custom">
                Descrições detalhadas da Empresa
                <hr class="custom">
                <s>Galeria de fotos</s>
                <hr class="custom">
                <s>Cardápio (p/ Restaurantes) e/ou Catálogo de Produtos/Serviços</s>
                <hr class="custom">
                <s>Localização</s>
                <hr class="custom">
                <s>Compartilhamento em mídias sociais</s>
<!--                <hr class="custom">-->
<!--                <s>Comunicação diretamente dos clientes com o e-mail pelo entre em contato/fale conosco</s>-->
              </div>
            </div>
            <a  style="color: #fafafa" href="planos/cadastro">
              <button style="width: 100%; padding: 10px; font-size: 18px" mat-button>Assinar</button>
            </a>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <br>
        <div>
          <p>* O plano Free só é válido para empresas locais, instaladas e em funcionamento em Campos Belos, exceto serviços.</p>
          <p>   ** Maiores informações do plano Ouro, consultar diretamente no telefone (62) 98232-6593 ou email portalcamposbelos@gmail.com</p>
        </div>
      </div>
    </div>
  </div>
</div>

