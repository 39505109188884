import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DefaultModule} from './layouts/default/default.module';
import {MatStepperModule} from '@angular/material/stepper';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCarouselModule} from '@ngmodule/material-carousel';
import {GuiaComercialComponent} from './modules/guia-comercial/guia-comercial.component';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {FlexLayoutModule} from '@angular/flex-layout';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {GuiaComercialDetalheComponent} from './modules/guia-comercial/guia-comercial-detalhe/guia-comercial-detalhe.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatGridListModule} from '@angular/material/grid-list';
import {AgmCoreModule} from '@agm/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTableModule} from '@angular/material/table';
import {NgxGalleryModule} from 'ngx-gallery-9';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import {TermosCondicoesComponent} from './modules/termos-condicoes/termos-condicoes.component';
import {PlanosComponent} from './modules/planos/planos.component';
import {NgxMaskModule} from 'ngx-mask';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {environment} from '../environments/environment';
import {NgxAudioPlayerModule} from 'ngx-audio-player';
import {AngMusicPlayerModule} from 'ang-music-player';
import { PoliticaPrivacidadeComponent } from './modules/politica-privacidade/politica-privacidade.component';



@NgModule({
  declarations: [
    AppComponent,
    GuiaComercialComponent,
    GuiaComercialDetalheComponent,
    TermosCondicoesComponent,
    PlanosComponent,
    PoliticaPrivacidadeComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatCardModule,
    MatCheckboxModule,
    MatCarouselModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    FlexLayoutModule,
    Ng2SearchPipeModule,
    MatDividerModule,
    MatGridListModule,
    AgmCoreModule.forRoot({
      apiKey: 'sua-chave-firebase'
    }),
    MatProgressBarModule,
    MatTableModule,
    NgxGalleryModule,
    MatTabsModule,
    MatSelectModule,
    NgxMaskModule,
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    NgxAudioPlayerModule,
    AngMusicPlayerModule
  ],
  providers: [],
    exports: [
        PlanosComponent
    ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
