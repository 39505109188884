import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HomeService} from './home.service';
import {Icontrato} from './icontrato';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{

  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  constructor(private formBuilder: FormBuilder, private service: HomeService) {
  }

  slides = [{image: 'assets/carrosel/1.jpg'}, {image: 'assets/carrosel/2.jpg'},
    {image: 'assets/carrosel/3.jpg'}];

  firstFormGroup: FormGroup;
  dadosTela: Icontrato = {
    nome: '',
    numeroContaCorrente: '',
    numeroContaAplicacao: '',
    situacao: '',
    cpf: ''
  };
  isMostrarInfo: boolean;
  breakpoint: number;
  @ViewChild('myNameElem') myNameElem: ElementRef;
  @ViewChild('myNameElem2') myNameElem2: ElementRef;
  t: string;
  @ViewChild('target') targetElement: any;
  @ViewChild('content') elementView: ElementRef;

  @Input() innerWidth = window.innerWidth;
  @Input() innerHeight = window.innerHeight;

  contentHeight: number;

  ngOnInit(): void {
    this.firstFormGroup = this.formBuilder.group({
      cpf: ['', Validators.required],
    });
    console.log(innerWidth);
    // Get the height of the element@ViewChild('userNameInput') userNameInput: ElementRef<HTMLInputElement>

  }



  consultar(): void {
    const resposta = this.service.read(this.firstFormGroup.value.cpf);
    if (resposta) {
      this.dadosTela = resposta;
      this.isMostrarInfo = true;
    } else {
      this.isMostrarInfo = false;
      this.service.showMessage('CPF inserido não é valido', true);
    }
  }

  reset(): void {
    this.isMostrarInfo = false;
  }
}
