import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Noticia} from './noticias.component';

@Injectable({
  providedIn: 'root'
})
export class NoticiasService {

  noticiasPrincipais: Noticia[] = [
    {
      id: 5,
      isMaisLida: false,
      descricao: 'Novidade: lançamento do Portal Campos Belos, sua nova fonte de notícias e informações na cidade!',
      categoria: 'portal',
      titulo: 'novidade:-lancamento-do-portal-campos-belos,-sua-fonte-de-noticias-e-informacoes-na-cidade',
      urlImagem1: 'assets/imagens/noticias/WhatsApp Image 2023-01-29 at 16.51.21.jpeg',
      urlImagem2: 'assets/imagens/noticias/WhatsApp Image 2023-01-29 at 16.51.22.jpeg',
      urlImagem3: 'assets/imagens/noticias/Cópia de Texto do seu parágrafo (1).jpg',
      isPaisagem: false,
      fonte: 'Portal da Campos Belos',
      dataHoraPublicacao: '00/00/0000 às 00:00',
      conteudo: '<p>Fique por dentro de tudo que acontece na sua cidade com o nosso novo Portal Campos Belos!. </p>' +
          '<p>Nossa plataforma oferece notícias atualizadas, fáceis de acessar e direcionadas especialmente à população da cidade. Além disso, oferecemos seções como <b>Notícias</b>, <b>Guia Comercial</b>, <b>Eventos</b>, <b>Empregos</b>, <b>Agenda</b>, <b>Imóveis</b> e <b>Veículos</b>. Tudo isso gratuitamente, com exceção do Guia Comercial <i>(que conta com um plano gratuito)</i>. </p>' +
          '<p>Cada seção do Portal Campos Belos tem um propósito específico e oferece benefícios únicos para a população.</p>' +
          '<p>A seção de <b style="color: #1C8C4D">Notícias</b> do Portal Campos Belos mantém a população informada sobre as últimas notícias e acontecimentos relevantes na cidade. Os moradores podem ficar por dentro das últimas notícias, desde política até cultura, saúde, segurança e outros assuntos relevantes. A seção é atualizada regularmente com novas informações, garantindo que os moradores estejam sempre bem informados.</p>' +
          '<p>O <b>Guia Comercial</b> é uma seção valiosa para os moradores, oferecendo uma lista completa de comerciantes locais, incluindo informações de contato e descrições dos produtos ou serviços oferecidos. Isso torna muito mais fácil para os moradores encontrar o que precisam, economizando tempo e esforço.</p>\n' +
          '<h2>Lançamentos em Breve</h2>' +
          '<p>A seção de <b>Eventos</b> , Manterá os moradores informados sobre todos os eventos programados na cidade, incluindo datas, horários, locais e descrições dos eventos. Isso permite que os moradores planejem suas agendas com antecedência e não percam nenhum evento importante.</p>\n' +
          '\n' +
          '\n' +
          '<p>A seção de <b>Empregos</b> oferecerá oportunidades de trabalho local para a população. Os moradores podem procurar vagas de trabalho, ver descrições detalhadas das posições disponíveis e se candidatar diretamente pelo site. Isso torna o processo de busca de emprego mais fácil e acessível para todos.</p>\n' +
          '\n' +
          '\n' +
          '<p>A <b>Agenda</b> é uma seção importante que manterá os moradores informados sobre eventos futuros programados na cidade. Os moradores podem ver uma lista de eventos futuros, incluindo datas, horários e descrições, e planejar suas agendas com antecedência.</p>\n' +
          '<p>A seção de <b>Imóveis</b> oferecerá uma ampla seleção de imóveis disponíveis na cidade, incluindo casas, apartamentos, terrenos e outras opções. Os moradores podem procurar imóveis, ver fotos e informações detalhadas sobre localização, preço, características e outros detalhes importantes.</p>\n' +
          '\n' +
          '\n' +
          '<p>A seção de <b>Veículos</b> oferecerá uma ampla seleção de carros à venda na região. Os moradores podem procurar carros, ver fotos, especificações e informações sobre preço. Isso torna muito mais fácil encontrar o veículo certo para as necessidades de cada um.</p>\n' +
          '<p>Em resumo, cada seção oferece informações valiosas e fáceis de acessar para a população, tornando o Portal Campos Belos uma ferramenta essencial para ficar por dentro de tudo que acontece na cidade.</p>'
    },

    {
      id: 6,
      isMaisLida: false,
      descricao: 'Escolha o plano perfeito para o seu negócio com nosso Guia Comercial.',
      categoria: 'portal',
      titulo: 'escolha-o-plano-perfeito-para-o-seu-negocio-com-nosso-guia-comercial',
      urlImagem1: 'assets/imagens/noticias/WhatsApp Image 2023-01-29 at 19.06.48.jpeg',
      urlImagem2: 'assets/imagens/noticias/WhatsApp Image 2023-01-29 at 19.06.49.jpeg',
      urlImagem3: 'assets/imagens/noticias/GUIA.jpg',
      isPaisagem: false,
      fonte: 'Portal da Campos Belos',
      dataHoraPublicacao: '00/00/0000 às 00:00',
      conteudo: '<p>Descubra as vantagens do Plano Grátis, Prata e Ouro e escolha a opção certa para alcançar o sucesso online.</p>\n' +
          '<p>Com nosso Guia Comercial, oferecemos três planos para atender às suas necessidades de divulgação de sua empresa.\n' +
          '<p>O <b>Plano Grátis</b> oferece uma base sólida para sua presença online, incluindo seu nome, categoria, telefone e endereço.' +
          ' Com este plano, você estará listado no Guia Comercial e poderá ser encontrado pelos clientes que procuram empresas como a sua.</p>\n' +
          '<p>O <b>Plano Prata</b> oferece mais recursos para melhorar a apresentação da sua empresa, incluindo sua logomarca e descrições detalhadas sobre o que você oferece. Este plano é ideal para empresas que querem se destacar no Guia Comercial e oferecer mais informações sobre sua empresa aos clientes.</p>\n' +
          '<p>O <b>Plano Ouro</b> oferece ainda mais recursos para aprimorar sua presença online, incluindo uma galeria de fotos, cardápio (para restaurantes) e/ou catálogo de produtos/serviços, localização, compartilhamento em mídias sociais e comunicação direta com seus clientes por meio de e-mail através da seção "entre em contato/fale conosco". Este plano é perfeito para empresas que querem se destacar ainda mais no Guia Comercial e oferecer uma experiência completa aos clientes.</p>'
    },

    {
      id: 7,
      isMaisLida: false,
      descricao: 'Como procurar um estabelecimento no Guia Comercial passo a passo.',
      categoria: 'portal',
      titulo: 'como-procurar-um-estabelecimento-no-guia-comercial-passo-a-passo',
      urlImagem1: 'assets/imagens/noticias/PASSO-A-PASSO (1).jpeg',
      urlImagem2: 'assets/imagens/noticias/PASSO-A-PASSO (2).jpeg',
      urlImagem3: 'https://i.ibb.co/MSn88hn/GUIA.png',
      isPaisagem: false,
      isImagemTotal: true,
      fonte: 'Portal da Campos Belos',
      dataHoraPublicacao: '00/00/0000 às 00:00',
      conteudo: '<p>Você pode encontrar facilmente os estabelecimentos que procura. Seguindo os passos acima, você poderá encontrar o estabelecimento desejado com rapidez e precisão.</p>' +
          '<p>Com o nosso guia comercial, você terá acesso a informações precisas e atualizadas sobre os estabelecimentos de sua região. Além disso, a facilidade e rapidez na busca o torna ainda mais valioso. Não perca mais tempo procurando informações dispersas, acesse agora o nosso guia comercial!</p>'
    }
  ];

  todasNoticias: Noticia[] = [

    {
      id: 5,
      isMaisLida: true,
      descricao: 'Novidade: lançamento do Portal Campos Belos, sua nova fonte de notícias e informações na cidade!',
      categoria: 'portal',
      titulo: 'novidade:-lancamento-do-portal-campos-belos,-sua-fonte-de-noticias-e-informacoes-na-cidade',
      urlImagem1: 'assets/imagens/noticias/WhatsApp Image 2023-01-29 at 16.51.21.jpeg',
      urlImagem2: 'assets/imagens/noticias/WhatsApp Image 2023-01-29 at 16.51.22.jpeg',
      urlImagem3: 'assets/imagens/noticias/Cópia de Texto do seu parágrafo (1).jpg',
      isPaisagem: false,
      fonte: 'Portal da Campos Belos',
      dataHoraPublicacao: '00/00/0000 às 00:00',
      conteudo: '<p>Fique por dentro de tudo que acontece na sua cidade com o nosso novo Portal Campos Belos!. </p>' +
        '<p>Nossa plataforma oferece notícias atualizadas, fáceis de acessar e direcionadas especialmente à população da cidade. Além disso, oferecemos seções como <b>Notícias</b>, <b>Guia Comercial</b>, <b>Eventos</b>, <b>Empregos</b>, <b>Agenda</b>, <b>Imóveis</b> e <b>Veículos</b>. Tudo isso gratuitamente, com exceção do Guia Comercial <i>(que conta com um plano gratuito)</i>. </p>' +
        '<p>Cada seção do Portal Campos Belos tem um propósito específico e oferece benefícios únicos para a população.</p>' +
        '<p>A seção de <b style="color: #1C8C4D">Notícias</b> mantém a população atualizada sobre as últimas novidades e ofertas imperdíveis no cenário comercial da cidade. Os moradores podem ficar por dentro das promoções, serviços oferecidos e eventos relacionados ao comércio local. A seção é constantemente atualizada com informações frescas, assegurando que os residentes estejam sempre informados sobre as oportunidades e benefícios disponíveis no setor comercial da região.</p>' +
        '<p>O <b>Guia Comercial</b> é uma seção valiosa para os moradores, oferecendo uma lista completa de comerciantes locais, incluindo informações de contato e descrições dos produtos ou serviços oferecidos. Isso torna muito mais fácil para os moradores encontrar o que precisam, economizando tempo e esforço.</p>\n' +
        '<h2>Lançamentos em Breve</h2>' +
        '<p>A seção de <b>Eventos</b> , Manterá os moradores informados sobre todos os eventos programados na cidade, incluindo datas, horários, locais e descrições dos eventos. Isso permite que os moradores planejem suas agendas com antecedência e não percam nenhum evento importante.</p>\n' +
        '\n' +
        '\n' +
        '<p>A seção de <b>Empregos</b> oferecerá oportunidades de trabalho local para a população. Os moradores podem procurar vagas de trabalho, ver descrições detalhadas das posições disponíveis e se candidatar diretamente pelo site. Isso torna o processo de busca de emprego mais fácil e acessível para todos.</p>\n' +
        '\n' +
        '\n' +
        '<p>A <b>Agenda</b> é uma seção importante que manterá os moradores informados sobre eventos futuros programados na cidade. Os moradores podem ver uma lista de eventos futuros, incluindo datas, horários e descrições, e planejar suas agendas com antecedência.</p>\n' +
        '<p>A seção de <b>Imóveis</b> oferecerá uma ampla seleção de imóveis disponíveis na cidade, incluindo casas, apartamentos, terrenos e outras opções. Os moradores podem procurar imóveis, ver fotos e informações detalhadas sobre localização, preço, características e outros detalhes importantes.</p>\n' +
        '\n' +
        '\n' +
        '<p>A seção de <b>Veículos</b> oferecerá uma ampla seleção de carros à venda na região. Os moradores podem procurar carros, ver fotos, especificações e informações sobre preço. Isso torna muito mais fácil encontrar o veículo certo para as necessidades de cada um.</p>\n' +
        '<p>Em resumo, cada seção oferece informações valiosas e fáceis de acessar para a população, tornando o Portal Campos Belos uma ferramenta essencial para ficar por dentro de tudo que acontece na cidade.</p>'
    },

    {
      id: 6,
      isMaisLida: true,
      descricao: 'Escolha o plano perfeito para o seu negócio com nosso Guia Comercial.',
      categoria: 'portal',
      titulo: 'escolha-o-plano-perfeito-para-o-seu-negocio-com-nosso-guia-comercial',
      urlImagem1: 'assets/imagens/noticias/WhatsApp Image 2023-01-29 at 19.06.48.jpeg',
      urlImagem2: 'assets/imagens/noticias/WhatsApp Image 2023-01-29 at 19.06.49.jpeg',
      urlImagem3: 'assets/imagens/noticias/GUIA.jpg',
      isPaisagem: false,
      fonte: 'Portal da Campos Belos',
      dataHoraPublicacao: '00/00/0000 às 00:00',
      conteudo: '<p>Descubra as vantagens do Plano Grátis, Prata e Ouro e escolha a opção certa para alcançar o sucesso online.</p>\n' +
        '<p>Com nosso Guia Comercial, oferecemos três planos para atender às suas necessidades de divulgação de sua empresa.\n' +
        '<p>O <b>Plano Grátis</b> oferece uma base sólida para sua presença online, incluindo seu nome, categoria, telefone e endereço.' +
        ' Com este plano, você estará listado no Guia Comercial e poderá ser encontrado pelos clientes que procuram empresas como a sua.</p>\n' +
        '<p>O <b>Plano Prata</b> oferece mais recursos para melhorar a apresentação da sua empresa, incluindo sua logomarca e descrições detalhadas sobre o que você oferece. Este plano é ideal para empresas que querem se destacar no Guia Comercial e oferecer mais informações sobre sua empresa aos clientes.</p>\n' +
        '<p>O <b>Plano Ouro</b> oferece ainda mais recursos para aprimorar sua presença online, incluindo uma galeria de fotos, cardápio (para restaurantes) e/ou catálogo de produtos/serviços, localização, compartilhamento em mídias sociais e comunicação direta com seus clientes por meio de e-mail através da seção "entre em contato/fale conosco". Este plano é perfeito para empresas que querem se destacar ainda mais no Guia Comercial e oferecer uma experiência completa aos clientes.</p>'
    },

    {
      id: 7,
      isMaisLida: true,
      descricao: 'Como procurar um estabelecimento no Guia Comercial passo a passo.',
      categoria: 'portal',
      titulo: 'como-procurar-um-estabelecimento-no-guia-comercial-passo-a-passo',
      urlImagem1: 'assets/imagens/noticias/PASSO-A-PASSO (1).jpeg',
      urlImagem2: 'assets/imagens/noticias/PASSO-A-PASSO (2).jpeg',
      urlImagem3: 'https://i.ibb.co/MSn88hn/GUIA.png',
      isPaisagem: false,
      isImagemTotal: true,
      fonte: 'Portal da Campos Belos',
      dataHoraPublicacao: '00/00/0000 às 00:00',
      conteudo: '<p>Você pode encontrar facilmente os estabelecimentos que procura. Seguindo os passos acima, você poderá encontrar o estabelecimento desejado com rapidez e precisão.</p>' +
        '<p>Com o nosso guia comercial, você terá acesso a informações precisas e atualizadas sobre os estabelecimentos de sua região. Além disso, a facilidade e rapidez na busca o torna ainda mais valioso. Não perca mais tempo procurando informações dispersas, acesse agora o nosso guia comercial!</p>'
    }

  ];

  constructor() {
  }


  private noticiasPrincipaisSource = new BehaviorSubject(this.noticiasPrincipais);
  listaNoticiasPrincipais = this.noticiasPrincipaisSource.asObservable();

  private todasNoticiasSource = new BehaviorSubject(this.todasNoticias);
  listaTodasNoticias = this.todasNoticiasSource.asObservable();
}
