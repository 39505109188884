<mat-toolbar class="color-10" [style.background-color]="isMobile? '#fff' : '#1C8C4D'">
  <mat-toolbar-row>
    <div *ngIf="isMobile" style="position: absolute">
      <button mat-icon-button (click)="toggleSideBar()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

    <div  style="margin-left: 15px;" *ngIf="!isMobile" style="position: absolute">
      <a href="/">
        <button mat-icon-button>
          <mat-icon style="color: #fafafa">home</mat-icon>
        </button>
      </a>
    </div>

    <img style="margin: 14px 10px 14px 10px; padding: 7px" *ngIf="isMobile" width="100%" height="100%"
         src="assets/imagens/logo/WhatsApp_Image_2022-08-08_at_11.56.47-removebg-preview.png">
    <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
      <!--        <ul fxLayout="row" fxLayoutGap="20px">-->
      <!--          <li>-->
      <!--            <button mat-button [matMenuTriggerFor]="menu">-->
      <!--              <mat-icon>person</mat-icon>-->
      <!--            </button>-->
      <!--            <mat-menu #menu="matMenu">-->
      <!--              <button mat-menu-item>-->
      <!--                <mat-icon>exit_to_app</mat-icon>-->
      <!--                Sign out-->
      <!--              </button>-->
      <!--            </mat-menu>-->
      <!--          </li>-->
      <!--        </ul>-->
    </div>
  </mat-toolbar-row>
</mat-toolbar>
