

<div>
  <div  style="height: 100%">


    <div style="padding-bottom: 25px; padding-top: 25px">
      <app-banner-principal-patrocinadores></app-banner-principal-patrocinadores>
    </div>
    <a style="cursor: pointer" href="/noticias/portal/novidade:-lancamento-do-portal-campos-belos,-sua-fonte-de-noticias-e-informacoes-na-cidade/5">
    <mat-card>

        <mat-card-title style="font-size: 25px; color: #1C8C4D">Novidade!!!</mat-card-title>
        <br>
        <mat-card-title [style.font-size]="isMobile ? '2em' : '40px'" [style.line-height]="isMobile ? '30px' : '45px'">
          Novidade: Novo Portal Campos Belos lançado!
        </mat-card-title>
        <mat-card-subtitle [style.font-size]="isMobile ? '13px' : '20px'" [style.line-height]="isMobile ? '15px' : '30px'">
          Lançamento do Portal Campos Belos, sua nova fonte de notícias e informações na cidade!
        </mat-card-subtitle>


    </mat-card>
    </a>
    <br>

    <div fxLayout="row wrap" fxLayoutGap="20px grid">
      <div fxFlex="75%" fxFlex.xs="100%" fxFlex.sm="100%">
        <app-noticias-princpipais></app-noticias-princpipais>
        <app-noticias-recentes></app-noticias-recentes>
        <mat-card *ngIf="false">
          <mat-card-title></mat-card-title>
          <app-planos-noticia *ngIf="false"></app-planos-noticia>
        </mat-card>
      </div>
      <div #content fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%">
        <app-patrocinadores></app-patrocinadores>
        <br>
        <mat-card *ngIf="false">
          <mat-card-title></mat-card-title>
          <app-planos-noticia></app-planos-noticia>
        </mat-card>
      </div>
    </div>
    <br>

    <!--    <div *ngIf="!isMobile">-->
    <!--      <div class="container">-->
    <!--        <div>-->
    <!--          <img class="testesamu" src="assets/video/Montanha Estúdio Fotográfico Capa para Facebook.gif"-->
    <!--               style="width:100%; height: 400px">-->
    <!--        </div>-->
    <!--        <div class="centered"><p style="font-size: 17px"><strong style="font-size: 32px;">Conheça nosso Guia-->
    <!--          Comercial</strong><br>-->
    <!--          Encontre facilmente números de telefone e endereços das empresas de Cabreúva.-->
    <!--        </p>-->
    <!--          <a href="/guia-comercial">-->
    <!--            <button mat-raised-button style="background-color: #8dc63f;color: #fafafa; font-size: 22px">Saiba mais</button>-->
    <!--          </a>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</div>










