<mat-nav-list>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/" (click)="toggleSideBar()" style="color: #1C8C4D">
    <mat-icon>home</mat-icon>
    Home
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/noticias" (click)="toggleSideBar()" style="color: #1C8C4D">
    <mat-icon>speaker_notes</mat-icon>
    Notícias
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/guia-comercial" (click)="toggleSideBar()" style="color: #1C8C4D">
    <mat-icon>search</mat-icon>
    Guia Comercial
  </a>
<!--  <mat-divider></mat-divider>-->
<!--  <a mat-list-item routerLinkActive="list-item-active" routerLink="/radio" (click)="toggleSideBar()" style="color: #1C8C4D">-->
<!--    <mat-icon>radio</mat-icon>-->
<!--    Rádio-->
<!--  </a>-->
  <mat-divider></mat-divider>
  <a mat-list-item routerLinkActive="list-item-active"  (click)="toggleSideBar()" style="color: #1C8C4D">
    <mat-icon>store_mall_directory</mat-icon>
    Imóveis <div style="padding-left: 5px"><sup style="background-color: #1C8C4D;color: #fafafa; border-radius: 5px; font-size: 9px; padding: 1.5px">Em breve</sup> </div>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item routerLinkActive="list-item-active"  (click)="toggleSideBar()" style="color: #1C8C4D">
    <mat-icon>view_agenda</mat-icon>
    Agenda <div style="padding-left: 5px"><sup style="background-color: #1C8C4D;color: #fafafa; border-radius: 5px; font-size: 9px; padding: 1.5px">Em breve</sup> </div>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item routerLinkActive="list-item-active"  (click)="toggleSideBar()" style="color: #1C8C4D">
    <mat-icon>directions_car</mat-icon>
    Veículos <div style="padding-left: 5px"><sup style="background-color: #1C8C4D;color: #fafafa; border-radius: 5px; font-size: 9px; padding: 1.5px">Em breve</sup> </div>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item routerLinkActive="list-item-active"  (click)="toggleSideBar()" style="color: #1C8C4D">
    <mat-icon>directions_bike</mat-icon>
    Esporte <div style="padding-left: 5px"><sup style="background-color: #1C8C4D;color: #fafafa; border-radius: 5px; font-size: 9px; padding: 1.5px">Em breve</sup> </div>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item routerLinkActive="list-item-active"  (click)="toggleSideBar()" style="color: #1C8C4D">
    <mat-icon>local_hospital</mat-icon>
    Saúde  <div style="padding-left: 5px"><sup style="background-color: #1C8C4D;color: #fafafa; border-radius: 5px; font-size: 9px; padding: 1.5px">Em breve</sup> </div>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/termos-e-condicoes" (click)="toggleSideBar()" style="color: #1C8C4D">
    <b>Acesso Rápido</b>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/termos-e-condicoes" (click)="toggleSideBar()" style="color: #1C8C4D">
    Termos de compromisso
  </a>
<!--  <mat-divider></mat-divider>-->
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/politica-de-privacidade" (click)="toggleSideBar()" style="color: #1C8C4D">
    Política de privacidade
  </a>
<!--  <mat-divider></mat-divider>-->
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/" (click)="toggleSideBar()" style="color: #1C8C4D">
    Sobre o nosso portal
  </a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/planos" (click)="toggleSideBar()" style="color: #1C8C4D">
    Planos
  </a>
  <mat-divider></mat-divider>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/" (click)="toggleSideBar()" style="color: #1C8C4D">
    <b>Campos Belos</b>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/" (click)="toggleSideBar()" style="color: #1C8C4D">
    História
  </a>
<!--  <mat-divider></mat-divider>-->
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/" (click)="toggleSideBar()" style="color: #1C8C4D">
    Galeria
  </a>
  <mat-divider></mat-divider>


  <a mat-list-item routerLinkActive="list-item-active" routerLink="/" (click)="toggleSideBar()" style="color: #1C8C4D">
    <b>Anuncie</b>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/" (click)="toggleSideBar()" style="color: #1C8C4D">
    Envie sua Notícia
  </a>
<!--  <mat-divider></mat-divider>-->
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/" (click)="toggleSideBar()" style="color: #1C8C4D">
    Cadastre seu Evento
  </a>
<!--  <mat-divider></mat-divider>-->
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/" (click)="toggleSideBar()" style="color: #1C8C4D">
    Cadastre sua Empresa
  </a>
<!--  <mat-divider></mat-divider>-->
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/" (click)="toggleSideBar()" style="color: #1C8C4D">
    Cadastre suas Vagas
  </a>
<!--  <mat-divider></mat-divider>-->
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/" (click)="toggleSideBar()" style="color: #1C8C4D">
    Cadastre seu Imóvel
  </a>
<!--  <mat-divider></mat-divider>-->
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/" (click)="toggleSideBar()" style="color: #1C8C4D">
    Cadastre seu Veículo
  </a>
<!--  <mat-divider></mat-divider>-->
</mat-nav-list>



