<app-header *ngIf="!isMobile" (toggleSideBarForMe)="sideBarToggler($event)"></app-header>
<mat-drawer-container>
  <mat-drawer mode="side" [opened]="sideBarOpen" [class.mobile-teste]="isMobile" style="background-color: #fff; color: #1C8C4D;">
    <img height="70px" style="margin: 10px 10px 0px -10px; padding: 5px" *ngIf="isMobile" width="100%"  src="assets/imagens/logo/WhatsApp_Image_2022-08-08_at_11.56.47-removebg-preview.png">
    <mat-divider></mat-divider>
    <app-sidebar (toggleSideBarForMe)="sideBarToggler($event)"></app-sidebar>
  </mat-drawer>
  <mat-drawer-content>
    <app-header *ngIf="isMobile" (toggleSideBarForMe)="sideBarToggler($event)"></app-header>
    <app-subheader></app-subheader>
    <div (window:resize)="onResize($event)"  class="inner" [style.width]="innerWidth > 1366 ? '65%' : '86%'">
      <router-outlet  (activate)="onActivate($event)"></router-outlet>
    </div>
    <app-home-footer *ngIf="!isMobile"></app-home-footer>
  </mat-drawer-content>
</mat-drawer-container>
