<div style=" padding-top: 15px">
  <app-banner-principal-patrocinadores></app-banner-principal-patrocinadores>
  <br>
  <mat-card>
    <div style="text-align: center">
      <mat-card-title [style.font-size]="isMobile? '25px' : '35px'" style="line-height: 25px">CADASTRO DE EMPRESAS
      </mat-card-title>
      <br>
      <mat-card-subtitle>Contrate um dos nossos planos e anuncie em nosso portal.</mat-card-subtitle>
      <br>
      <h1 *ngIf="flag" style="color: #1C8C4D">Cadastro finalizado com sucesso, em até 24 HRs nossa equipe entrará em
        contato ;-))</h1>
    </div>
    <div style="padding: 15px" *ngIf="!flag">
      <div>
        <mat-card-content>
          <form [formGroup]="guiaForm" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <div fxLayout="row wrap" fxLayoutGap="16px grid">
              <mat-form-field fxFlex="50%" fxFlex.sm="50%" fxFlex.md="50%" fxFlex.xs="100%">
                <mat-label>Nome da empresa</mat-label>
                <input type="text" matInput placeholder="Ex. Portal Campos Belos"
                       formControlName="razaoSocial" required maxlength="255">
                <mat-icon matSuffix>edit</mat-icon>
                <mat-error *ngIf="!guiaForm.get('razaoSocial').valid">Campo obrigatório!</mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="50%" fxFlex.sm="50%" fxFlex.md="50%" fxFlex.xs="100%">
                <mat-label>Nome do responsável</mat-label>
                <input type="text" matInput placeholder="Ex. João da Silva" formControlName="responsavel"
                       maxlength="255">
              </mat-form-field>
              <mat-form-field fxFlex="50%" fxFlex.sm="50%" fxFlex.md="50%" fxFlex.xs="100%">
                <mat-label>Telefone</mat-label>
                <input mask="(00) 00000-0000" type="text" required matInput placeholder="Ex.  (62) 98177-8510"
                       formControlName="telefone" maxlength="255">
                <mat-icon matSuffix>edit</mat-icon>
              </mat-form-field>
              <mat-form-field fxFlex="50%" fxFlex.sm="50%" fxFlex.md="50%" fxFlex.xs="100%">
                <mat-label>Email</mat-label>
                <input type="text" matInput placeholder="Ex. portalcamposbelos@gmail.com" formControlName="email"
                       maxlength="255">
              </mat-form-field>
              <mat-form-field appearance="fill" fxFlex="33%" fxFlex.sm="33%" fxFlex.md="33%" fxFlex.xs="100%">
                <mat-label>Planos</mat-label>
                <mat-select (selectionChange)="buscarCidades()" formControlName="plano" required>
                  <mat-option *ngFor="let plano of planos" [value]="plano.id">
                    {{plano.nome}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!guiaForm.get('plano').valid">Campo obrigatório!</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" fxFlex="33%" fxFlex.sm="33%" fxFlex.md="33%" fxFlex.xs="100%">
                <mat-label>Estado</mat-label>
                <mat-select (selectionChange)="buscarCidades()" formControlName="uf" required>
                  <mat-option *ngFor="let estado of estados" [value]="estado.sigla">
                    {{estado.sigla}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!guiaForm.get('uf').valid">Campo obrigatório!</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" fxFlex="33%" fxFlex.sm="33%" fxFlex.md="33%" fxFlex.xs="100%">
                <mat-label>Cidade</mat-label>
                <mat-select formControlName="municipio" required>
                  <mat-option *ngFor="let cidade of cidades" [value]="cidade.nome">
                    {{cidade.nome}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!guiaForm.get('municipio').valid">Campo obrigatório!</mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="30%" fxFlex.sm="50%" fxFlex.md="30%" fxFlex.xs="50%">
                <mat-label>Logradouro</mat-label>
                <input type="text" matInput
                       formControlName="logradouro" required maxlength="255">
                <mat-icon matSuffix>edit</mat-icon>
                <mat-error *ngIf="!guiaForm.get('logradouro').valid">Campo obrigatório!</mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="30%" fxFlex.sm="50%" fxFlex.md="30%" fxFlex.xs="50%">
                <mat-label>Complemento</mat-label>
                <input type="text" matInput formControlName="complemento"
                       maxlength="255">
              </mat-form-field>
              <mat-form-field fxFlex="30%" fxFlex.sm="50%" fxFlex.md="25%" fxFlex.xs="50%">
                <mat-label>Bairro</mat-label>
                <input type="text" matInput required formControlName="bairro"
                       maxlength="255">
                <mat-icon matSuffix>edit</mat-icon>
                <mat-error *ngIf="!guiaForm.get('bairro').valid">Campo obrigatório!</mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="10%" fxFlex.sm="50%" fxFlex.md="15%" fxFlex.xs="50%">
                <mat-label>Número</mat-label>
                <input type="text" matInput formControlName="numero"
                       maxlength="255">
                <mat-icon matSuffix>edit</mat-icon>
                <mat-error *ngIf="!guiaForm.get('numero').valid">Campo obrigatório!</mat-error>
              </mat-form-field>
              <div fxFlex="30%" fxFlex.sm="100%" fxFlex.md="100%" fxFlex.xs="100%" >
                <div>
                  {{this.guiaForm.value.plano}}
                  <label>Selecione sua logo marca</label><br>
                  <input  (change)="uploadFile($event)" type="file" accept="image/*" />
                </div>
              </div>
              <mat-form-field fxFlex="30%" fxFlex.sm="50%" fxFlex.md="30%" fxFlex.xs="100%">
                <mat-select  multiple placeholder="Selecione suas categorias" formControlName="categorias" required (selectionChange)="changed($event)">
                  <mat-select-trigger>
                    <span *ngIf="(guiaForm.value.categorias.length || 0) > 1" class="example-additional-selection">
                      {{guiaForm.value.categorias[0]}}
                      (+{{(guiaForm.value.categorias.length || 0) - 1}} {{guiaForm.value.categorias?.length === 2 ? 'categoria' : 'categorias'}}
                      )
                    </span>
                    <span *ngIf="(guiaForm.value.categorias?.length || 0)  === 1" class="example-additional-selection">
                      {{guiaForm.value.categorias[0]}}
                    </span>
                  </mat-select-trigger>
                  <mat-option *ngFor="let topping of categorias" [value]="topping">{{topping}}</mat-option>
                </mat-select>
                <mat-error *ngIf="!guiaForm.get('categorias').valid">Campo obrigatório!</mat-error>
              </mat-form-field>
              <mat-card fxFlex="100%" fxFlex.sm="50%" fxFlex.md="15%" fxFlex.xs="50%" *ngIf="guiaForm.value.categorias.length > 0">
                <mat-card-subtitle>Categorias</mat-card-subtitle>
                <mat-card-content>
                  <div style="padding: 10px">
                     <span style="padding: 5px" *ngFor="let item of guiaForm.value.categorias">
                    <span
                      style="background-color: #1C8C4D;color: #fafafa; border-radius: 5px; font-size: 14px;padding: 4px">{{item}}</span>
                  </span>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </form>
        </mat-card-content>
        <button *ngIf="!guiaForm.invalid" style="background-color: #1C8C4D; color: #fafafa" mat-button (click)="cadastrar()">Cadastrar</button>
      </div>
    </div>
  </mat-card>
</div>






