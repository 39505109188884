import {Component, Input, OnInit} from '@angular/core';
import {NoticiasService} from '../noticias/noticias.service';
import {Noticia} from '../noticias/noticias.component';

@Component({
  selector: 'app-noticias-recentes',
  templateUrl: './noticias-recentes.component.html',
  styleUrls: ['./noticias-recentes.component.scss']
})


export class NoticiasRecentesComponent implements OnInit {

  @Input() idNoticiaRemocao: number;
  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  listaNoticiaRecente: Noticia[] = [];

  constructor(private noticiaService: NoticiasService) { }

  ngOnInit(): void {
    this.noticiaService.listaTodasNoticias.subscribe(value => {
      const tamanho = value.length;
      if (Number(tamanho) > 6){
        this.listaNoticiaRecente = value.filter(value1 => Number(value1.id) >
          (Number(tamanho) - 6) && value1.id !== this.idNoticiaRemocao).reverse();
      }else{
        this.listaNoticiaRecente = value;
      }
    });
  }

}
