import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-banner-principal-patrocinadores',
  templateUrl: './banner-principal-patrocinadores.component.html',
  styleUrls: ['./banner-principal-patrocinadores.component.scss']
})
export class BannerPrincipalPatrocinadoresComponent implements OnInit {

  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  constructor() { }

  ngOnInit(): void {
  }


}
