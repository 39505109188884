<div class="color-10" style="margin-top: 15px;color: #ffffff">
  <div class="container inner">
    <div>
      <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <!--        Policial-->
        <div fxLayout="row wrap" fxLayoutGap="0px grid">
          <div  fxFlex="25%" fxFlex.xs="25%" fxFlex.sm="25%" fxFlex.md="25%">
            <div  class="example-card">
              <div style="padding-top: 20px">
                <table style=" margin: 0 auto; text-align: left" >
                  <tr>
                    <td style="font-size: 16px; font-family: Open Sans, sans-serif"> <b>Acesso rápido</b></td>
                  </tr>
                  <tr style="margin-top: 15px">
                    <td style="font-size: 14px; font-family: Open Sans, sans-serif"> <a href="/termos-e-condicoes">Termos de compromisso</a></td>
                  </tr>
                  <tr style="margin-top: 15px">
                    <td style="font-size: 14px; font-family: Open Sans, sans-serif"><a href="/politica-de-privacidade">Política de privacidade</a></td>
                  </tr>
                  <tr style="margin-top: 15px">
                    <td style="font-size: 14px; font-family: Open Sans, sans-serif">Sobre o nosso portal</td>
                  </tr>
                  <tr style="margin-top: 15px">
                    <td style="font-size: 14px; font-family: Open Sans, sans-serif"><a href="/planos">Planos</a></td>
                  </tr>
                </table>
              </div>

            </div>
          </div>
          <div  fxFlex="25%" fxFlex.xs="25%" fxFlex.sm="25%" fxFlex.md="25%">
            <div  class="example-card">
              <div style="padding-top: 20px">
                <table style=" margin: 0 auto; text-align: left" >
                  <tr>
                    <td style="font-size: 16px; font-family: Open Sans, sans-serif"><b>Campos Belos</b></td>
                  </tr>
                  <tr style="margin-top: 15px">
                    <td style="font-size: 14px; font-family: Open Sans, sans-serif">História</td>
                  </tr>
                  <tr style="margin-top: 15px">
                    <td style="font-size: 14px; font-family: Open Sans, sans-serif">Galeria</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div  fxFlex="25%" fxFlex.xs="25%" fxFlex.sm="25%" fxFlex.md="25%">
            <div  class="example-card">
              <div style="padding-top: 20px">
                <table style=" margin: 0 auto; text-align: left" >
                  <tr>
                    <td style="font-size: 16px; font-family: Open Sans, sans-serif"><b>Anuncie</b></td>
                  </tr>
                  <tr style="margin-top: 15px">
                    <a>
                    <td style="font-size: 14px; font-family: Open Sans, sans-serif">Envie sua Notícia</td>
                    </a>
                  </tr>
<!--                  <tr style="margin-top: 15px">-->
<!--                    <td style="font-size: 14px; font-family: Open Sans, sans-serif">Cadastre seu Evento</td>-->
<!--                  </tr>-->
                  <tr style="margin-top: 15px">
                    <a href="/planos/cadastro">
                    <td style="font-size: 14px; font-family: Open Sans, sans-serif">Cadastre sua Empresa</td>
                    </a>
                  </tr>
<!--                  <tr style="margin-top: 15px">-->
<!--                    <td style="font-size: 14px; font-family: Open Sans, sans-serif">Cadastre suas Vagas</td>-->
<!--                  </tr>-->
<!--                  <tr style="margin-top: 15px">-->
<!--                    <td style="font-size: 14px; font-family: Open Sans, sans-serif">Cadastre seu Imóvel</td>-->
<!--                  </tr>-->
<!--                  <tr style="margin-top: 15px">-->
<!--                    <td style="font-size: 14px; font-family: Open Sans, sans-serif">Cadastre seu Veículo</td>-->
<!--                  </tr>-->
                </table>
              </div>

            </div>
          </div>
          <div  fxFlex="25%" fxFlex.xs="25%" fxFlex.sm="25%" fxFlex.md="25%">
            <div  class="example-card">
              <div style="padding-top: 20px">
                <table style=" margin: 0 auto; text-align: left" >
                  <tr>
                    <td style="font-size: 16px; font-family: Open Sans, sans-serif"><b>Conteúdo</b></td>
                  </tr>
                  <tr style="margin-top: 15px">
                    <a href="/noticias">
                    <td style="font-size: 14px; font-family: Open Sans, sans-serif">Notícias</td>
                    </a>
                  </tr>
                  <tr style="margin-top: 15px">
                    <a href="/guia-comercial">
                      <td style="font-size: 14px; font-family: Open Sans, sans-serif">Guia Comercial</td>
                    </a>
                  </tr>
                  <tr style="margin-top: 15px">
                    <td style="font-size: 14px; font-family: Open Sans, sans-serif">Agenda <sup title="Em breve">Em breve</sup></td>
                  </tr>
                  <tr style="margin-top: 15px">
                    <td style="font-size: 14px; font-family: Open Sans, sans-serif">Empregos <sup title="Em breve">Em breve</sup></td>
                  </tr>
                  <tr style="margin-top: 15px">
                    <td style="font-size: 14px; font-family: Open Sans, sans-serif">Imóveis <sup title="Em breve">Em breve</sup></td>
                  </tr>
                  <tr style="margin-top: 15px">
                    <td style="font-size: 14px; font-family: Open Sans, sans-serif">Descontos <sup title="Em breve">Em breve</sup></td>
                  </tr>
                  <tr style="margin-top: 15px">
                    <td style="font-size: 14px; font-family: Open Sans, sans-serif">Eventos <sup title="Em breve">Em breve</sup></td>
                  </tr>
                  <tr style="margin-top: 15px">
                    <td style="font-size: 14px; font-family: Open Sans, sans-serif">Veículos <sup title="Em breve">Em breve</sup></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>
