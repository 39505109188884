<div style="padding-top: 20px">
    <mat-card style="height: 100%">
        <mat-card-header>
            <!--        <mat-card-subtitle>Guia Comercial/detalhe</mat-card-subtitle>-->
            <mat-card-title>{{ infoGuiaTela.nome.toUpperCase() }}</mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <br>
        <mat-card-content>
            <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
                <div fxLayout="row wrap" fxLayoutGap="25px grid">
                    <div fxFlex="30%" fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="30%">
                        <div style="padding-top: 20px; padding-left: 20px">
                            <img style="max-height: 170px" mat-card-image [src]="infoGuiaTela.imagem2">
                        </div>
                    </div>
                    <div fxFlex="70%" fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="70%">
                        <table style="width: 100%;">
                            <tr style="padding: 20px">
                                <td>
                                    <mat-icon> location_on</mat-icon>
                                </td>
                                <td>
                                    <spam *ngIf="infoGuiaTela.plano !== 1 ? true : false">{{ infoGuiaTela.endereco }}</spam>
                                </td>
                            </tr>
                            <tr *ngIf="infoGuiaTela.email">
                                <td>
                                    <mat-icon>email</mat-icon>
                                </td>
                                <td>
                                    <spam>{{ infoGuiaTela.email }}</spam>
                                </td>
                            </tr>
                            <tr *ngIf="infoGuiaTela.telefone">
                                <td>
                                    <mat-icon *ngIf="infoGuiaTela.telefone">phone</mat-icon>
                                </td>
                                <td>
                                    <spam>{{ infoGuiaTela.telefone |  mask: '+00 (00) 00000-0000' }}</spam>
                                </td>
                            </tr>
                            <tr *ngIf="infoGuiaTela.funcionamento">
                                <td>
                                    <mat-icon>watch_later</mat-icon>
                                </td>
                                <td>{{ infoGuiaTela.funcionamento }}</td>
                            </tr>
                            <tr *ngIf="infoGuiaTela.site">
                                <td>
                                    <mat-icon>vpn_lock</mat-icon>
                                </td>
                                <td><a [href]="infoGuiaTela.site" target="_blank">Site</a></td>
                            </tr>
                        </table>
                    </div>
                    <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">
                        <mat-divider></mat-divider>
                        <br>
                        <div>
                            <mat-card-title>DESCRIÇÃO</mat-card-title>
                            <br>
                            <mat-card-content [innerHTML]="infoGuiaTela.descricao">

                            </mat-card-content>
                        </div>
                        <br>
                        <br>
                        <mat-divider></mat-divider>
                    </div>

                    <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%"
                         *ngIf="infoGuiaTela.isProdutos">
                        <div>
                            <mat-card-title>CARDÁPIO</mat-card-title>
                            <br>
                            <mat-card-content>
                                <mat-tab-group>
                                    <mat-tab [label]="item.categoria.toUpperCase()" *ngFor="let item of cardapio">
                                        <!--                                              <mat-card *ngIf="!isMobile">-->
                                        <!--                                                <div *ngFor="let item of [0, 1, 2];" style="padding: 10px">-->
                                        <!--                                                  <div>-->
                                        <!--                                                    <div fxFlex="10%" fxFlex.xs="10%" fxFlex.sm="10%" fxFlex.md="10%" fxLayoutGap="">-->
                                        <!--                                                      <span> <img width="100px" style="border-radius: 10px; padding-left: 1px" height="100px" src="https://s3-sa-east-1.amazonaws.com/cardapiando/images/thumb/5d3ba2dcb43b5.png"></span>-->
                                        <!--                                                    </div>-->
                                        <!--                                                    <div fxFlex="90%" fxFlex.xs="70%" fxFlex.sm="90%" fxFlex.md="90%" style=" margin-left: 5px">-->
                                        <!--                                                      <span style="font-family: Roboto,Helvetica Neue,Helvetica,Arial,sans-serif; font-size: 14px; font-weight: bold">094: X - A MODA DA CANTINA ARAEDES</span><br>-->
                                        <!--                                                      <span style="font-family: Roboto,Helvetica Neue,Helvetica,Arial,sans-serif; font-size: 14px; line-height: 1.4; color: #333;">Hambúrguer, calabresa, batata palha, pepino, ervilha, milho verde, Tomate, Alface, mussarela e presunto.</span><br>-->
                                        <!--                                                      <span  style="background-color: rgba(0,0,0,.03);; padding: 5px; text-align: end">R$23,00</span>-->
                                        <!--                                                    </div>-->
                                        <!--                                                  </div>-->
                                        <!--                                                  <mat-divider></mat-divider>-->
                                        <!--                                                </div>-->
                                        <!--                                              </mat-card>-->
                                        <mat-card>
                                            <div *ngFor="let produto of item.listaProduto; let i = index"
                                                 style="padding: 10px">
                                                <div style="text-align: end" *ngIf="false">
                              <span>
                                  <button mat-button title="Adicionar carrinho" (click)="addProduto(item, produto)">
                                  <mat-icon>add</mat-icon>
                                  </button>
                                </span>
                                                </div>
                                                <div style="margin-left: -25px">
                                                    <div fxFlex="8%" fxFlex.xs="30%" fxFlex.sm="15%" fxFlex.md="10%">
                              <span> <img width="100px" style="border-radius: 10px; padding-left: 1px" height="100px"
                                          [src]="produto.imagem"></span>
                                                    </div>
                                                    <div fxFlex="92%" fxFlex.xs="70%" fxFlex.sm="85%" fxFlex.md="70%"
                                                         style=" margin-left: 20px">
                              <span
                                      style="font-family: Roboto,Helvetica Neue,Helvetica,Arial,sans-serif; font-size: 18px; font-weight: bold">{{ produto.titulo.toUpperCase() }}</span><br>
                                                        <span
                                                                style="font-family: Roboto,Helvetica Neue,Helvetica,Arial,sans-serif; font-size: 1rem; line-height: 1.4; color: #333;">{{ produto.descricao }}</span><br>
                                                        <span
                                                                style="background-color: rgba(0,0,0,.03);  padding: 3px; font-size: 17px; font-weight: bold">{{ produto.preco | currency: 'BRL' }}</span>
                                                    </div>
                                                </div>
                                                <br>
                                                <mat-divider *ngIf="i < item.listaProduto.length -1"></mat-divider>
                                            </div>
                                            <div *ngIf="carrinho.length > 0" id="mynode" style="background-color: #fff">
                                                <mat-card-title
                                                        style="background-color: rgba(0,0,0,.03); padding: 15px">Resumo
                                                    do seu
                                                    pedido
                                                </mat-card-title>
                                                <div *ngFor="let item of carrinho; let i = index"
                                                     style="padding: 5px 5px 5px 15px;"
                                                     [style.background-color]="i % 2 ? 'rgba(0,0,0,.03)' : '#fff'">
                                                    <b>Descrição:</b> {{ item.titulo }} <br>
                                                    <b>V. Uni:</b> {{ item.preco | currency: 'BRL' }} <br>
                                                    <b>Qtd:</b> {{ item.quantidade }}x<br>
                                                    <b>V. Total:</b> {{ item.valorTotal | currency: 'BRL' }}<br>
                                                    <!--                            <b>OBS:</b> {{item.obs}}-->
                                                </div>
                                                <br>
                                                <mat-card-title style="background-color: rgba(0,0,0,.03); padding: 15px"
                                                                #mynode>
                                                    <div style="font-weight: bold; font-size: 15px; ">
                                                        TOTAL: {{ this.total | currency: 'BRL' }}
                                                    </div>
                                                </mat-card-title>
                                            </div>
                                            <!--                        <button mat-raised-button (click)="testee()">Teste</button>-->
                                        </mat-card>
                                    </mat-tab>
                                </mat-tab-group>
                            </mat-card-content>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                    <!--          <div  style="margin-bottom: -25px" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">-->
                    <!--            <mat-card-subtitle>INFORME OS DADOS ABAIXO</mat-card-subtitle>-->
                    <!--          </div>-->
                    <!--          <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">-->
                    <!--            <mat-form-field class="example-full-width">-->
                    <!--              <mat-label>Nome</mat-label>-->
                    <!--              <input matInput placeholder="Ex. João da Silva" >-->
                    <!--            </mat-form-field>-->
                    <!--            <mat-form-field class="example-full-width">-->
                    <!--              <mat-label>Observação:</mat-label>-->
                    <!--              <textarea matInput placeholder="Observações"></textarea>-->
                    <!--            </mat-form-field>-->
                    <!--          </div>-->
                    <!--          <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">-->
                    <!--            <mat-form-field class="example-full-width">-->
                    <!--              <mat-label>Forma de pagamento</mat-label>-->
                    <!--              <mat-select>-->
                    <!--                <mat-option *ngFor="let state of formaPagamentos" [value]="state">{{state}}</mat-option>-->
                    <!--              </mat-select>-->
                    <!--            </mat-form-field>-->
                    <!--            <mat-form-field class="example-full-width">-->
                    <!--              <mat-label>Endereço:</mat-label>-->
                    <!--              <textarea matInput placeholder="Observações"></textarea>-->
                    <!--            </mat-form-field>-->
                    <!--            <br>-->
                    <!--            <br>-->
                    <!--            <mat-divider></mat-divider>-->
                    <!--          </div>-->
                    <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%"
                         *ngIf="infoGuiaTela.isFormaPagamento">
                        <div>
                            <mat-card-title>FORMAS DE PAGAMENTOS</mat-card-title>
                            <br>
                            <mat-card-content style="width: 100%">
                                <img width="100%" style="max-width: 500px" src="assets/imagens/exemplo-pagamentos.png">
                                <!--                  <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">-->

                                <!--                    &lt;!&ndash;- Note that these columns can be defined in any order.-->
                                <!--                          The actual rendered columns are set as a property on the row definition" &ndash;&gt;-->

                                <!--                    &lt;!&ndash; Position Column &ndash;&gt;-->
                                <!--                    <ng-container matColumnDef="dia">-->
                                <!--                      <th mat-header-cell *matHeaderCellDef> No.</th>-->
                                <!--                      <td mat-cell *matCellDef="let element"><b>{{element.dia}}</b></td>-->
                                <!--                    </ng-container>-->

                                <!--                    &lt;!&ndash; Name Column &ndash;&gt;-->
                                <!--                    <ng-container matColumnDef="horas">-->
                                <!--                      <th mat-header-cell *matHeaderCellDef> Name</th>-->
                                <!--                      <td mat-cell *matCellDef="let element"> {{element.horas}} </td>-->
                                <!--                    </ng-container>-->

                                <!--                    &lt;!&ndash;                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>&ndash;&gt;-->
                                <!--                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>-->
                                <!--                  </table>-->
                            </mat-card-content>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                    <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%"
                         *ngIf="infoGuiaTela.isFormaPagamento">
                        <div>
                            <mat-card-title>NOSSA EQUIPE</mat-card-title>
                            <br>
                            <mat-card-content>

                                <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
                                    <!--        Policial-->
                                    <div fxLayout="row wrap" fxLayoutGap="16px grid">
                                        <div *ngFor="let radialista of radialistas" fxFlex="25%" fxFlex.xs="100%"
                                             fxFlex.sm="33%"
                                             fxFlex.md="33%">
                                            <mat-card>
                                                <mat-card-header>
                                                    <div mat-card-avatar></div>
                                                    <mat-card-title>{{ radialista.nome }}</mat-card-title>
                                                    <mat-card-subtitle>{{ radialista.email }}</mat-card-subtitle>
                                                </mat-card-header>
                                                <img style="max-height: 200px" mat-card-image [src]="radialista.foto">
                                                <mat-card-content>
                                                    <table style="width: 100%">
                                                        <tr>
                                                            <td style="width: 90%">
                                                            </td>
                                                            <td>
                                                                <svg style="width:40px;height:40px;color: #1C8C4D; padding-top: 6px"
                                                                     viewBox="0 0 24 24">
                                                                    <path fill="currentColor"
                                                                          d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"/>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <img src="https://upload.wikimedia.org/wikipedia/commons/9/96/Instagram.svg"
                                                                     style="width:40px;height:37px;color: #1C8C4D; padding-top: 6px"
                                                                     viewBox="0 0 24 24"/>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </mat-card-content>
                                            </mat-card>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </div>
                        <br>
                        <br>
                        <mat-divider></mat-divider>
                    </div>
                    <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%"
                         *ngIf="infoGuiaTela.plano === 3">
                        <div>
                            <mat-card-title>GALERIA</mat-card-title>
                            <br>
                            <mat-card-content>
                                <ngx-gallery [innerHTML]="" [options]="galleryOptions" [images]="infoGuiaTela.galeria"
                                             style="width: 100%;"
                                             [style.height]="isMobile ? '250px' : '500px'"></ngx-gallery>
                            </mat-card-content>
                        </div>
                        <br>
                        <br>
                        <!--            <mat-divider></mat-divider>-->
                    </div>
                    <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%" *ngIf="false">
                        <div>
                            <div fxLayout="row wrap" fxLayoutGap="25px grid">
                                <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
                                    <div>
                                        <mat-card-title>LOCALIZAÇÃO</mat-card-title>
                                        <br>
                                        <agm-map [latitude]="lat" [longitude]="lng" [(zoom)]="zoom">
                                            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                                            <agm-fullscreen-control></agm-fullscreen-control>
                                        </agm-map>
                                    </div>
                                </div>
                                <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
                                    <div style="padding-left: 20px">
                                        <mat-card-title>ENTRE EM CONTATO</mat-card-title>
                                        <br>
                                        <mat-card-subtitle>Entre em contato com a empresa</mat-card-subtitle>
                                        <mat-card-content>
                                            <form class="example-form">

                                                <mat-form-field class="example-full-width">
                                                    <mat-label>Nome</mat-label>
                                                    <input matInput placeholder="Ex. JOÃO DA SILVA">
                                                </mat-form-field>

                                                <mat-form-field class="example-full-width">
                                                    <mat-label>Email</mat-label>
                                                    <input matInput placeholder="Ex. joaosilva@gmail.com">
                                                </mat-form-field>

                                                <mat-form-field class="example-full-width">
                                                    <mat-label>Telefone</mat-label>
                                                    <input matInput placeholder="Ex. (62) 99999-9999">
                                                </mat-form-field>

                                                <mat-form-field class="example-full-width">
                                                    <mat-label>Mensagem</mat-label>
                                                    <textarea matInput [rows]="4"></textarea>
                                                </mat-form-field>
                                                <div style="text-align: end">
                                                    <button mat-raised-button
                                                            style="min-width: 150px; background-color: #1C8C4D; color: #fafafa">
                                                        Enviar
                                                    </button>
                                                </div>
                                            </form>
                                        </mat-card-content>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>
        </mat-card-content>
    </mat-card>
</div>
