import {Component, OnInit} from '@angular/core';
import {NoticiasService} from './noticias.service';
import {ActivatedRoute} from '@angular/router';


export interface Noticia {
  id: number;
  urlImagem1: string;
  urlImagem2?: string;

  urlImagem3?: string;
  conteudo: string;
  dataHoraPublicacao: string;
  fonte: string;
  titulo: string;
  categoria: string;
  descricao: string;
  isMaisLida?: boolean;
  isPaisagem?: boolean;
  isImagemTotal?: boolean;
}

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss']
})
export class NoticiasComponent implements OnInit {
  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  constructor(private noticiaService: NoticiasService, private route: ActivatedRoute) {
  }

  noticiasrelacionadas: Noticia[];
  noticia: Noticia;

  ngOnInit(): void {
    this.noticiaService.listaTodasNoticias.subscribe(value => {

      const id = this.route.snapshot.paramMap.get('id');
      console.log(id);
      // @ts-ignore
      this.noticia = value.filter(value1 => value1.id === Number(id))[0];
      if (!this.noticia) {
        this.noticiaService.listaNoticiasPrincipais.subscribe(value2 => {
          // tslint:disable-next-line:no-shadowed-variable
          this.noticia = value2.filter(value2 => value2.id === Number(id))[0];
        });
      }
      this.noticiasrelacionadas = value.filter(value1 => (value1.categoria === this.noticia.categoria) && (value1.id !== this.noticia.id));
    });
  }

}
