
<div style="padding-top: 25px; padding-bottom: 25px">

<!--  <div style="padding-top: 25px; padding-bottom: 25px">-->
<!--    <app-banner-principal-patrocinadores></app-banner-principal-patrocinadores>-->
<!--  </div>-->

  <div fxLayout="row wrap" fxLayoutGap="20px grid">
    <div fxFlex="75%" fxFlex.xs="100%" fxFlex.sm="100%">
      <div>
        <mat-card-subtitle *ngIf="!isMobile"><a href="/">Home</a>  > <a href="/noticias">Notícias</a> > {{noticia.categoria}} > {{noticia.descricao}}</mat-card-subtitle>
        <strong class="categoria" [style.font-size]="isMobile ? '15px' : '20px'" >{{noticia.categoria}}</strong><br>
        <mat-card-title class="descricao" [style.font-size]="isMobile ? '25px' : '35px'"
                        [style.line-height]="isMobile ? '25px' : '35px'"> <b>{{noticia.descricao}}</b></mat-card-title>
        <mat-card-subtitle [style.font-size]="isMobile ? '10px' : '15px'"
                           [style.line-height]="isMobile ? '15px' : '30px'">
          <div style="padding-top: 5px"> Publicado em {{noticia.dataHoraPublicacao}}</div>

        </mat-card-subtitle>
        <div style="margin-top: 10px; text-align: center" *ngIf="!noticia.isImagemTotal">
          <img *ngIf="!noticia.isPaisagem" style="max-height: 500px; max-width: 400px" [src]="noticia.urlImagem3">
          <img *ngIf="noticia.isPaisagem" style="max-height: 400px; max-width: 600px" [src]="noticia.urlImagem3">
        </div>
        <div style="margin-top: 10px; text-align: center" *ngIf="noticia.isImagemTotal">
          <img style="max-width: 600px" [src]="noticia.urlImagem3">
        </div>
        <br>
        <div>
          <mat-card-content>
            <div style="line-height: 1.6; font-size: 16px" [innerHTML]="noticia.conteudo">
            </div>
            <div *ngIf="noticia.id === 6" style="text-align: center">
              <app-planos-noticia></app-planos-noticia>
            </div>
            <br>
            <p style="line-height: 1.6; font-size: 14px"><b>Fonte:</b> {{noticia.fonte}}</p>
          </mat-card-content>
        </div>
      </div>
      <div style="padding-top: 25px;" *ngIf="noticiasrelacionadas.length > 0">
        <div>
          <mat-card-content>
            <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
              <div fxLayout="row wrap" fxLayoutGap="16px grid">
                <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">
                  <mat-card-title style="margin-top: 10px;font-size: 28px;">Notícias relacionadas</mat-card-title>
                  <div class="base">
                    <div class="pBar"></div>
                  </div>
                </div>
                <div fxFlex="33%" fxFlex.xs="50%" fxFlex.sm="50%" fxFlex.md="50%"
                     *ngFor="let item of listaNoticiaCarregadas">
                  <a
                    href="/noticias/{{item.categoria + '/' + item.titulo + '/' + item.id}}"
                    class="other-news--home small-flex flex-wrap" title="{{item.descricao}}">

                    <div class="news-small zoom-img no-margin">
                      <img
                        [src]="item.urlImagem2"
                        class="responsive-img loaded" alt="{{item.descricao}}"
                        data-was-processed="true">
                    </div>

                    <div>
                        <span class="categoria">
                                    <strong>{{item.categoria}}</strong>
                        </span>
                      <p class="descricao">
                        <strong> {{item.descricao}} </strong>
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </mat-card-content>

          <div id="outer" *ngIf="isCarregando">
            <div id="inner"><mat-spinner color="primary"></mat-spinner></div>
          </div>
          <button *ngIf="!isCarregando && quantidadeRegistro < noticiasrelacionadas.length"  (click)="carregarNoticias(true)" mat-raised-button
                  style="width: 100%; background-color: #8dc63f; color: #fafafa; font-size: 18px">Carregar mais
            notícias
          </button>
<!--          <br>-->
<!--          <br>-->
<!--          <br>-->
<!--          <img width="100%"-->
<!--               src="http://farmaciasantoandre.com.br/wp-content/uploads/2017/12/WhatsApp-Image-2021-09-09-at-11.55.39.jpeg"-->
<!--               data-src="http://farmaciasantoandre.com.br/wp-content/uploads/2017/12/WhatsApp-Image-2021-09-09-at-11.55.39.jpeg">-->
        </div>
      </div>
<!--      <app-noticias-recentes [idNoticiaRemocao]="noticia.id"></app-noticias-recentes>-->
    </div>

    <div #content fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" *ngIf="!isMobile">
      <app-patrocinadores></app-patrocinadores>
    </div>
    <br>
  </div>
</div>

